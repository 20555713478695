
/**
 * ICONS
 * */

export const CHECK_CALENDAR = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/check_calendar.svg';
export const COGNITION = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/cognition.svg';
export const CONNECTIVITY = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/connectivity.svg';
export const EARN_ICON = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/earn_icon.svg';
export const FILTER = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/filter.svg';
export const GOAL = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/goal.svg';
export const GOOGLESHEETS_ICON = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/googlesheets_icon.svg';
export const HOUSE_ICON = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/house_icon.svg';
export const LOCK_PERSON = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/lock_person.svg';
export const RESPONSABILITY = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/responsability.svg';
export const STATISTICS_UP = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/statistics_up.svg';
export const TAGS = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/tags.svg';
export const TALK_IT = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/talk_it.svg';
export const TECHNOLOGY = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/technology.svg';
export const TODO_ICON = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/todo_icon.svg';
export const TRUST = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/trust.svg';
export const USER_MAGNIFY = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/user_magnify.svg';
export const WARNING_TALK = 'https://winahost.s3.eu-west-1.amazonaws.com/images/icons/warning_talk.svg';
