import {Grid, Link, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {WORK_SPACE} from "../../../Shared/Constants/imageConstants";

const JoinToUs = () => {

    const {t} = useTranslation();

    const textAdvantages = `
    <ul>
        <li>
            Horario flexible
        </li>
        <li>
            Formación Comercial y Servicios
        </li>
        <li>
            Plan de Carrera y Retribución Progresiva
        </li>
    </ul>
    `;

    return (
        <Grid container justifyContent={'center'} mt={9} pt={9} pb={5} style={{backgroundColor: 'var(--main-winahost-gray-color)'}}>
            <Grid item xs={12} sm={10} md={9} lg={9} xl={8}>
                <Grid container justifyContent={'center'} spacing={3}>
                    <Grid item xs={10} sm={10} md={10} lg={7}>

                        <Typography variant={'h2'} fontSize={'1.7em'} mb={2} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup('Únete a nosotros')}/>

                        <Typography variant={'body1'} mb={5} dangerouslySetInnerHTML={createMarkup('Estamos buscando Agentes Winahost que se quieran unir a nuestro equipo de captación de clientes. Si crees que posees las cualidades de gestor comercial orientado al servicio, ponte en contacto con nosotros')}/>

                        <Typography variant={'body1'} mb={2} dangerouslySetInnerHTML={createMarkup('Buscamos personas con ganas de crecer en un entorno dinámico')}/>

                        <Typography variant={'body1'} mb={5} dangerouslySetInnerHTML={createMarkup('Ventajas de formar parte de Winahost:')}/>

                        <Typography variant={'body1'} mb={5} dangerouslySetInnerHTML={createMarkup(textAdvantages)}/>

                        <Link rel={'canonical'} href={`mailto:admin@winahost.com`}
                              underline="none"
                              className={'custom-winahost-button'}
                        >
                            <span>
                                {'Contacto'}
                            </span>
                        </Link>

                    </Grid>
                    <Grid item xs={10} sm={10} md={10} lg={5} mt={5}>
                        <img src={WORK_SPACE} alt={"work_space"} width={'100%'}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default JoinToUs;
