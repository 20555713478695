import {Grid} from "@mui/material";
import SmallForm from "../../../components/forms/smallForm";
import {useState} from "react";
import {LeadInterface} from "../../../interfaces/leadInterface";
import {upsertHubspotContact} from "../../../Shared/hooks/upsertHubspotContact";
import {useTranslation} from "react-i18next";
import {getAirDnaRentalizeSummaryData} from "../../mainSection/hooks/getAirDnaRentalizeSummaryData";
import RevenueParameters from "./revenueParameters";
import {leadBase} from "../../../Shared/baseData/leadBaseData";
import {formatLeadMergeData, formatRentalizeWithLeadMergeData} from "../../../hooks/leadsHook";
import {calculateRentalizerByExtras} from "../../../Shared/utils/calculateRentalizerByExtras";
import {gtagLandingPricePageConversion, metaLeadTrack} from "../../../hooks/socialMeditionTags";


const CalculatorPriceBase = () => {

    const {t} = useTranslation();

    const [currentFormPage, setCurrentFormPage] = useState(1);

    const [lead, setLead] = useState<LeadInterface>(leadBase);

    const [rentalizerData, setRentalizerData] = useState(null);

    const [rentalizerErrorMessage, setRentalizerErrorMessage] = useState<string | null>(null);

    const getRevenueData = () => {
        lead.origin.origin = "price";
        const mergeLeadData = formatLeadMergeData(lead);
        getAirDnaRentalizeSummaryData(mergeLeadData).then((response) => {
            if (response.error) {
                setRentalizerErrorMessage(response.error.message);
                const leadMergeData = formatLeadMergeData(lead);
                sendHubspotInformation(leadMergeData);
            } else {
                const newResponse = calculateRentalizerByExtras(lead, response);
                setRentalizerData(newResponse);
                const leadMergeData = formatRentalizeWithLeadMergeData(lead, response);
                sendHubspotInformation(leadMergeData);
            }
        });
    }

    const sendHubspotInformation = (leadMergeData: Object) => {

        metaLeadTrack();
        gtagLandingPricePageConversion();

        upsertHubspotContact(leadMergeData);
    }

    const showRentalizerData = () => {
        if (currentFormPage === 1 || currentFormPage === 4) {

            return (
                <Grid item xs={11} sm={11} md={6} lg={6}>
                    <RevenueParameters rentalizerData={rentalizerData}/>
                </Grid>
            );
        }
    }

    const getWidthAccordingPage = () => {
        return (currentFormPage === 1 || currentFormPage === 4) ? 6 : 11;
    }

    return (
        <Grid container justifyContent={'center'} mt={5} className={'calculatorDegree'} id={'calculatorDegree'}>
            <Grid item xs={11} sm={11} md={11} lg={10} xl={9}>
                <Grid container justifyContent={'center'} spacing={3}>
                    <Grid item xs={11} sm={11} md={getWidthAccordingPage()}>
                        <SmallForm lead={lead} setLead={setLead}
                                   currentFormPage={currentFormPage}
                                   setCurrentFormPage={setCurrentFormPage}
                                   customFunction={getRevenueData}
                                   rentalizerData={rentalizerData}
                                   rentalizerErrorMessage={rentalizerErrorMessage}
                        />
                    </Grid>
                    {showRentalizerData()}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CalculatorPriceBase;
