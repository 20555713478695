import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import PlanPricePageSection from "./planPricePageSection";
import {WORKER_GUY} from "../../../Shared/Constants/imageConstants";

const ProfessionalSection = () => {

    const textProperties = [
        'Consultoría de expansión para inversores inmobiliarios',
        'Outsorcing de alquiler turístico para inmobiliarias',
        'Gestión de propiedades de Edificios',
        'Gestión de propiedades de Promociones Inmobiliarias',
        'Gestión de propiedades de Sociedades Patrimoniales',
        'Gestión de propiedades de Inmobiliarias',
        'Análisis de Rentabilidad de Propiedades'
    ];

    return (
        <PlanPricePageSection>
            <Grid container justifyContent={'center'} mt={5} mb={5}>
                <Grid item xs={11} sm={10} md={8} lg={8}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={11} sm={11} md={8} lg={6}>

                            <Typography variant={'body1'} fontSize={'1.7em'}
                                        fontWeight={600}
                                        dangerouslySetInnerHTML={createMarkup('Profesionales')}/>

                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('Tu plan personalizado a medida')}/>

                            <Grid item xs={12} mt={5} p={1}>
                                <ul>
                                    {textProperties.map((textProperty, index) => {
                                        return (
                                            <li key={index}>
                                                <Typography variant={'body1'} mt={2}
                                                            dangerouslySetInnerHTML={createMarkup(textProperty)}/>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Grid>

                        </Grid>
                        <Grid item xs={11} sm={11} md={4} lg={6}>
                            <img src={WORKER_GUY} alt={"worker_guy"} width={'100%'}
                                 style={{borderRadius: 30}}/>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'center'} mt={8}>
                        <Grid item xs={11} sm={11} md={10} lg={8}>

                            <Typography variant={'body1'} fontSize={'1.6em'} fontWeight={600} mb={5}
                                        dangerouslySetInnerHTML={createMarkup('¿És el Plan Profesional la mejor opción para mi?')}/>

                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('Si eres un inversor, profesional inmobiliario, administrador de fincas o multipropietario contacta con nosotros, te haremos un plan a medida para gestionar tus propiedades.')}/>

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </PlanPricePageSection>
    );
}

export default ProfessionalSection;
