import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const FormContactAtSuccessfullyResponse = () => {
    return (
        <Grid item xs={12} md={6}>
            <Grid sx={{
                backgroundColor: 'linear-gradient(to bottom, #FFFFFF, #367E7F)',
                padding: 3,
                borderRadius: 10,
                textAlign: 'center'
            }}>
                <Box sx={{ paddingRight: { xs: "0", md: "40px" } }}>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: { xs: "1.5rem", sm: "1.8rem" },
                            fontWeight: 600,
                            color: "#000",
                            textAlign: "center",
                            marginBottom: "24px",
                        }}
                    >
                        Formulario enviado correctamente
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: { xs: "1rem", sm: "1.2rem" },
                            fontWeight: 400,
                            color: '#008080',
                            textAlign: "center",
                            marginBottom: "8px",
                        }}
                    >
                        En breve un agente de Winahost se pondrá en contacto contigo
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default FormContactAtSuccessfullyResponse;