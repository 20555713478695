import {FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import CustomTextField from "../../../components/customComponents/customTextField";
import {ChangeEvent, useEffect, useState} from "react";
import {LeadInterface} from "../../../interfaces/leadInterface";
import {changeLeadHookData, changePropertyHookData} from "../../../hooks/leadsHook";
import {validationContactFormPage} from "../contact";
import {validationEmail, validationPhone, validationTextNotEmpty} from "../../../Shared/utils/validations";

interface props {
    lead: LeadInterface,
    setLead: Function,
    validationFormPage: validationContactFormPage,
    setValidationFormPage: Function
}

const UserForm = ({lead, setLead, validationFormPage, setValidationFormPage}: props) => {

    const [showMessage, setShowMessage] = useState({
        name: {
            error: false,
            message: 'Este campo es obligatorio'
        },
        email: {
            error: false,
            message: 'Debe de ser correo electrónico válido'
        },
        phone: {
            error: false,
            message: 'Debe de ser un teléfono válido'
        }
    });

    const changeUserData = (key: string, value: string) => {

        if (key === 'name') validationNameForm(value);
        if (key === 'email') validationEmailForm(value);
        if (key === 'phone') validationPhoneForm(value);

        changeLeadHookData(lead, setLead, key, value);
    }

    const changePropertyData = (key: string, value: string) => {
        changePropertyHookData(lead, setLead, key, value);
    }


    const validateUserDataForm = () => {
        if (lead.user.name !== '' && lead.user.email !== '' && lead.user.phone !== '' &&
        !showMessage.name.error && !showMessage.email.error && !showMessage.phone.error) {
            setValidationFormPage({...validationFormPage, user: true});
        } else {
            setValidationFormPage({...validationFormPage, user: false});
        }
    }


    const validationNameForm = (value: string) => {
        const isValid = validationTextNotEmpty(value);
        setShowMessage(prevState => ({...prevState, name: {...prevState.name, error: isValid}}));
    }

    const validationEmailForm = (value: string) => {
        const isValid = validationEmail(value);
        setShowMessage(prevState => ({...prevState, email: {...prevState.email, error: isValid}}));
    }

    const validationPhoneForm = (value: string) => {
        const isValid = validationPhone(value);
        setShowMessage(prevState => ({...prevState, phone: {...prevState.phone, error: isValid}}));
    }


    useEffect(() => {
        validateUserDataForm();
    }, [lead.user]);

    return (
        <Grid container justifyContent={'left'} spacing={3} mt={1}>
            <Grid item xs={12}>
                <Typography variant={'body1'} className={'contactFormTitle'} mb={3}
                            dangerouslySetInnerHTML={createMarkup('Tu información de contacto')}/>
                <Typography variant={'body1'}  className={'contactFormSubTitle'} mb={3}
                            dangerouslySetInnerHTML={createMarkup('Un Agente Winahost te contactará para que tengas un informe de tus ingresos más preciso y conozcas como te podemos ayudar')}/>
            </Grid>

            <Grid item xs={12} md={6}>
                <CustomTextField fullWidth
                                 required={true}
                                 value={lead.user.name}
                                 label={'Nombre completo'}
                                 id="userContactFormNameDetail"
                                 error={showMessage.name.error}
                                 helperText={showMessage.name.error ? showMessage.name.message : ''}
                                 onChange={(e: ChangeEvent<HTMLInputElement>) => changeUserData('name', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <CustomTextField fullWidth
                                 required={true}
                                 value={lead.user.email}
                                 label={'Correo electrónico'}
                                 id="userContactFormEmailDetail"
                                 error={showMessage.email.error}
                                 helperText={showMessage.email.error ? showMessage.email.message : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => changeUserData('email', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <CustomTextField fullWidth
                                 required={true}
                                 value={lead.user.phone}
                                 label={'Número de teléfono'}
                                 id="userContactFormPhoneDetail"
                                 error={showMessage.phone.error}
                                 helperText={showMessage.phone.error ? showMessage.phone.message : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => changeUserData('phone', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">¿Quieres que un Agente Winahost te llame para darte información más detallada de nuestros servicios para ayudarte a rentabilizar tu vivienda?</FormLabel>
                    <RadioGroup aria-label="call" name="call" value={lead.property.call}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => changePropertyData('call', e.target.value)}>
                        <FormControlLabel value="yes" control={<Radio/>} label="Sí"/>
                        <FormControlLabel value="no" control={<Radio/>} label="No"/>
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default UserForm;
