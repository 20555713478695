import { Grid, Typography } from "@mui/material";
import ReviewComponent from "./reviewComponent";
import React from "react";
import { createMarkup } from "../../../../Shared/utils/textUtilsResources";
import { useTranslation } from "react-i18next";
import getReviewCollection, { newReviewInterface } from "./getReviewCollection";

const ReviewClientsTrustBoxAt = () => {
    const { t } = useTranslation();
    const reviews = getReviewCollection();

    return (
        <Grid container justifyContent="center" pt={5} pb={1} style={{ backgroundColor: 'var(--main-winahost-gray-color)' }}>
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                <Grid container justifyContent="center">
                    <Grid item xs={10} sm={11} md={9} lg={9} textAlign="center">
                        <Typography
                            variant="body1"
                            fontSize="1.5rem"
                            mb={3}
                            fontWeight={700}
                            color="black"
                            dangerouslySetInnerHTML={createMarkup('Propietarios como tú ya confían en nuestra gestión integral')}
                        />
                    </Grid>
                    <Grid item xs={10} sm={11} md={11} lg={11}>
                        <Grid container justifyContent="center" spacing={5}>
                            {reviews.map((review: newReviewInterface, index: number) => {
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        xs={12} 
                                        sm={6} 
                                        md={4} 
                                        lg={3} 
                                    >
                                        <ReviewComponent review={review} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ReviewClientsTrustBoxAt;