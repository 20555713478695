import {Box, Grid, Skeleton} from "@mui/material";

const SimpleSkeleton = () => {
    return (
        <Grid container justifyContent={'center'} mt={5}>
            <Grid item xs={12} sm={11} md={10} lg={8} xl={8}>
                <Box>
                    <Skeleton variant="rounded" width={'100%'} height={118}/>
                    <Skeleton/>
                    <Skeleton width="60%"/>
                </Box>
            </Grid>
        </Grid>
    );
}

export default SimpleSkeleton;
