import BaseLayout from "../../Shared/layouts/baseLayout";
import LegalTextContent from "../../Shared/sharedTexts/legalTextContent";


const LegalText = () => {
    return (
        <BaseLayout>
            <LegalTextContent/>
        </BaseLayout>
    );
}

export default LegalText;
