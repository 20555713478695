import {Grid, Link, Paper, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import {useTranslation} from "react-i18next";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { yellow } from '@mui/material/colors';
import {ES_PRICES, PREMIUM} from "../../../routes/constantsRoutes";



const PremiumCard = ({priceBoxHeight}: { priceBoxHeight: number }) => {

    const {t} = useTranslation();

    const textProperties = [
        'Gestión completa de reservas',
        'Check In / Check Out',
        'Limpieza y lavandería',
        'Mantenimiento',
        'Anuncio de plataformas de reservas',
        //'Fotografía profesional especializada',
        'Optimización de precios y plataformas',
        'Atención 24/7 de los 365 días del año',
        'Comunicación multiidioma',
        'Servicios exclusivos huéspedes',
        'Asesor Winahost',
        'Posicionamiento Premium',
        'Plataforma App Propietarios',
        'Big Data'
    ];

    return (
        <Grid item xs={12} sm={12} md={4}>
            <Paper
                className={'priceBoxContent'}
                style={{
                    backgroundColor: 'var(--main-winahost-color)',
                    borderRadius: 30,
                    padding: '0 40px 20px 40px',
                    height: `${priceBoxHeight === 0 ? 'auto' : priceBoxHeight + 'px'}`
                }}
                elevation={3}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12}>
                        <Typography variant={'body1'} fontSize={'1.7em'} mt={3} color={'white'} fontWeight={500}
                                    dangerouslySetInnerHTML={createMarkup('Premium')}/>

                        <Typography variant={'body1'} fontSize={'1.7em'} mt={3} color={'white'} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup('19%')}/>
                        <Typography variant={'body1'} fontSize={'.7em'} color={'white'} fontWeight={400}
                                    dangerouslySetInnerHTML={createMarkup('* sobre el precio neto de la reserva')}/>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        {textProperties.map((property: string, index: number) => {
                            return (
                                <Grid key={index} container justifyContent={'center'} mt={1.5}>
                                    <Grid item xs={1}>
                                        <CheckCircleOutlineIcon sx={{ color: yellow[50] }}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography key={index} variant={'body1'} color={'white'}
                                                    dangerouslySetInnerHTML={createMarkup(property)}/>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={5} mb={3}>
                    <Link rel={'canonical'} key={'main_page'} href={ES_PRICES + PREMIUM}
                          underline="none"
                          className={'custom-winahost-white-button priceCardButton'}
                    >
                            <span style={{fontWeight: 'bold'}}>
                                {'Empezar'}
                            </span>
                    </Link>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default PremiumCard;
