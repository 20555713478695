import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {MAP_TECHNOLOGYC} from "../../../Shared/Constants/imageConstants";

const WinahostMap = () => {

    const {t} = useTranslation('', {keyPrefix: 'mainPage.winahostMap'});

    return (
        <Grid container justifyContent={'center'} mt={8}>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'} textAlign={'center'}>
                    <Grid item xs={10} sm={10} md={7}>
                        <Typography variant={'h2'}
                                    fontSize={'1.7em'}
                                    mb={5}
                                    color={'black'}
                                    fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup(t('winahostMapTitle'))}/>

                        <img src={MAP_TECHNOLOGYC}
                             alt={"image map"}
                             width={'100%'}
                             style={{borderRadius: 30}}
                        />

                        <Typography variant={'body1'}
                                    mt={5}
                                    mb={5}
                                    color={'black'}

                                    dangerouslySetInnerHTML={createMarkup(t('winahostMapSecondParagraph'))}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default WinahostMap;
