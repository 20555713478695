import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as React from "react";

interface props {
    title: string,
    content: any,
    open: boolean,
    setOpen: Function,
    customFunction: Function|null
}

const CustomDialog = ({title, content, open, setOpen, customFunction}: props) => {

    const handleAgreeDialog = () => {
        setOpen(false);
        if (typeof customFunction === 'function') {
            customFunction();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle id="dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                    {content}
            </DialogContent>
            <DialogActions style={{margin: '0 20px 0 50px'}}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                    variant={'outlined'}
                    className={'custom-winahost-button'}
                    onClick={() => handleAgreeDialog()}
                >
                    {'Aceptar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CustomDialog;
