import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {Button, Grid, Typography} from "@mui/material";
import {createMarkup} from "../../Shared/utils/textUtilsResources";


export const ContactLinkDetails = () => {

    return (
        <Grid container justifyContent={'center'}>
            <Grid item textAlign={'center'}>
                <Grid container justifyContent={'center'}>
                    <Grid item textAlign={'center'}>
                        <Button variant="contained"
                                style={{
                                    backgroundColor: 'white',
                                    borderRadius: 25,
                                    color: 'var(--main-winahost-color)',
                                    fontWeight: 'bold'
                                }}
                                startIcon={<WhatsAppIcon style={{color: 'var(--main-winahost-color)'}}/>}
                                onClick={() => window.open(`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_WINAHOST_PHONE}`, '__blank')}
                        >
                            WhatsApp
                        </Button>
                        <Typography variant={'body1'} fontSize={'1em'} color={'white'} mt={3}
                                    dangerouslySetInnerHTML={createMarkup('+34 930 500 225')}/>

                        <Typography variant={'body1'} fontSize={'1em'} color={'white'} mt={3}
                                    dangerouslySetInnerHTML={createMarkup('Passeig de Gràcia, 95 - 5º 1ª, 08008 Barcelona')}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
