import {Grid, Button, Typography} from "@mui/material";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {ChangeEvent, useState} from "react";
import {Option} from "react-google-places-autocomplete/build/types";
import {ControlProps, GroupBase, MenuProps} from "react-select";
import {t} from "i18next";
import CustomTextField from "../customComponents/customTextField";
import {LeadInterface} from "../../interfaces/leadInterface";
import * as React from "react";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import {changePropertyHookData} from "../../hooks/leadsHook";

interface props {
    lead: LeadInterface,
    setLead: Function,
    customFunction: Function,
}

const PropertyForm = (
    {
        lead,
        setLead,
        customFunction
    }: props) => {

    const [googleOptions, setGoogleOptions] = useState<string[]>([]);

    const [buttons, setButtons] = useState({
        pool: false,
        parking: false,
        terrace: false,
    });

    const validationBase = {
        location: {
            error: false,
            classReference: 'selectBorderCustomStyled'
        },
        bedrooms: {
            error: false,
            message: ''
        },
        bathrooms: {
            error: false,
            message: ''
        },
        accommodates: {
            error: false,
            message: ''
        }
    }

    const [errorMessage, setErrorMessage] = useState(validationBase);

    const changeLocationData = (key: string, value: any) => {
        changePropertyHookData(lead, setLead, key, value);
    }

    const callCustomFunction = () => {
        if (validateFormData()) {
            customFunction();
        }
    }

    const changeLeadData = (key: string, value: any) => {

        if ((key === 'bedrooms' || key === 'bathrooms' || key === 'accommodates') && value < 0) {
            value = 0;
        }
        changePropertyHookData(lead, setLead, key, value);
    }

    const validateFormData = (): boolean => {

        setErrorMessage(validationBase);
        let errorFlag = true;
        let validationBaseCopy = validationBase;

        if (lead.property.bedrooms == 0) {
            validationBaseCopy.bedrooms.error = true;
            validationBaseCopy.bedrooms.message = 'No puede ser 0';
            errorFlag = false;
        }

        if (lead.property.bathrooms == 0) {
            validationBaseCopy.bathrooms.error = true;
            validationBaseCopy.bathrooms.message = 'No puede ser 0';
            errorFlag = false;
        }

        if (lead.property.accommodates == 0) {
            validationBaseCopy.accommodates.error = true;
            validationBaseCopy.accommodates.message = 'No puede ser 0';
            errorFlag = false;
        }

        if (errorFlag) {
            setErrorMessage(validationBaseCopy);
        }

        return errorFlag;
    }

    const handleManagementExtras = (extra: string) => {
        if (lead.property.extras) {
            const indexValue = lead.property.extras.indexOf(extra);

            if (indexValue === -1) {
                lead.property.extras.push(extra);
            } else {
                lead.property.extras.splice(indexValue, 1);
            }
        } else {
            lead.property.extras = [extra];
        }

        _changeButtonsState(extra)
    }

    const _changeButtonsState = (key: string) => {
        setButtons({...buttons, [key]: !buttons[key]})
    }


    return (
        <>
            <Grid container p={2} spacing={3}>
                <Grid item xs={12}>
                    <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                        apiOptions={{
                            region: 'es'
                        }}
                        selectProps={{
                            defaultInputValue: lead.property.address,
                            placeholder: t('mainPage.leadForm.propertyForm.propertyFormInput'),
                            onChange: (e: any) => changeLocationData('address', e.label),
                            onKeyDown: () => {
                                googleOptions.splice(0, googleOptions.length);
                            },
                            getOptionLabel: (option) => {
                                googleOptions.push(option.label);
                                return option.label;
                            },
                            noOptionsMessage: () => 'Busca una localización',
                            instanceId: 'react-select-3-live-region',
                            className: `selectCustomStyled ${errorMessage.location.classReference}`,
                            classNames: {
                                control: (props: ControlProps<Option, false, GroupBase<Option>>) => {
                                    return 'selectInputCustomStyled'
                                },
                                menu: (props: MenuProps<Option, false, GroupBase<Option>>) => {
                                    return 'selectMenuCustomStyled';
                                }
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CustomTextField fullWidth
                                     size={'small'}
                                     error={errorMessage.bedrooms.error}
                                     helperText={errorMessage.bedrooms.message}
                                     type={'number'}
                                     aria-valuemin={0}
                                     value={lead.property.bedrooms}
                                     label={t('mainPage.leadForm.propertyForm.propertyFormRooms')}
                                     id="propertyDetailRoomForm"
                                     onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('bedrooms', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CustomTextField fullWidth
                                     size={'small'}
                                     error={errorMessage.bathrooms.error}
                                     helperText={errorMessage.bathrooms.message}
                                     type={'number'}
                                     value={lead.property.bathrooms}
                                     label={t('mainPage.leadForm.propertyForm.propertyFormBathrooms')}
                                     id="propertyDetalBathroomsForm"
                                     onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('bathrooms', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CustomTextField fullWidth
                                     size={'small'}
                                     error={errorMessage.accommodates.error}
                                     helperText={errorMessage.accommodates.message}
                                     type={'number'}
                                     value={lead.property.accommodates}
                                     label={t('mainPage.leadForm.propertyForm.propertyFormPax')}
                                     id="propertyDetalBathroomsForm"
                                     onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('accommodates', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={12} mt={3}>
                    <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                                mb={2}
                                dangerouslySetInnerHTML={createMarkup('¿Tienes alguno de estos extras?')}/>
                    <Grid container justifyContent={'left'} spacing={2} columns={8}>
                        <Grid item xs={12} sm={2}>
                            <Button variant={`${(buttons.pool) ? 'contained': 'outlined'}`} color="primary"
                                    onClick={() => handleManagementExtras('pool')}>{'Piscina'}</Button>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button variant={`${(buttons.parking) ? 'contained': 'outlined'}`} color="primary"
                                    onClick={() => handleManagementExtras('parking')}>{'Parking'}</Button>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button variant={`${(buttons.terrace) ? 'contained': 'outlined'}`} color="primary"
                                    onClick={() => handleManagementExtras('terrace')}>{'Terraza'}</Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        disabled={lead.property.address.length <= 3}
                        variant={'outlined'}
                        className={'custom-winahost-button'}
                        onClick={() => callCustomFunction()}
                    >
                        {t('mainPage.leadForm.propertyForm.propertyFormButtonSend')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default PropertyForm
