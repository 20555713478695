import * as React from "react";
import '../../Styles/pricesPage.css';

import BaseLayout from "../../Shared/layouts/baseLayout";
import ProfitableProperty from "./sections/profitableProperty";
import Statistics from "./sections/statistics";
import PricesSection from "./sections/pricesSection";
import CalculatorPriceBase from "./sections/calculatorPriceBase";
import ExampleInvoice from "./sections/exampleInvoice";
import {useEffect, useState} from "react";


const Prices = () => {

    const [priceBoxHeight, setPriceBoxHeight] = useState<number>(0);

    useEffect(() => {
        setTimeout(() => {
            const priceBoxContents = document.getElementsByClassName('priceBoxContent');

            let maxHeight = 0;
            for (let i = 0; i < priceBoxContents.length; i++) {
                // @ts-ignore
                let height = priceBoxContents[i].clientHeight;
                maxHeight = Math.max(maxHeight, height);
            }

            setPriceBoxHeight(maxHeight);
        }, 1000);
    }, []);

    return (
        <BaseLayout>
            <ProfitableProperty />
            <PricesSection priceBoxHeight={priceBoxHeight}/>
            <ExampleInvoice />
            <CalculatorPriceBase />
            <Statistics />
        </BaseLayout>
    );
}

export default Prices;
