import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Reviews from "../../mainSection/sections/components/reviews";

const ReviewClientsTrustBoxLanding = () => {
    const { t } = useTranslation();
    const reviews = Reviews();

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            mt={3}
            sx={{
                fontFamily: "'Poppins', sans-serif",
                textAlign: "left",
                px: 2,
                background: "linear-gradient(to bottom, #FFFFFF, #367E7F)",
                color: "black",
                borderRadius: "10px",
                marginTop: "-18px",
                paddingTop: "120px",
                paddingBottom: "24px",
            }}
        >
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} sm={10} md={8} lg={6}>
                    <Typography
                        variant="h2"
                        fontSize={{ xs: "22px", sm: "28px" }}
                        mb={1}
                        fontWeight={700}
                        color="black"
                    >
                        ¿Qué te ofrece Winahost?
                    </Typography>

                    <Typography
                        variant="h3"
                        fontSize={{ xs: "18px", sm: "24px" }}
                        mb={3}
                        fontWeight={600}
                        color="black"
                    >
                        La mejor tecnología y gestión para maximizar tus ingresos
                    </Typography>

                    <Typography
                        variant="body1"
                        fontSize={{ xs: "14px", sm: "16px" }}
                        mb={6}
                        fontWeight={400}
                        lineHeight={1.7}
                    >
                        En <strong>Winahost</strong>, combinamos la{" "}
                        <strong>tecnología de punta</strong> con nuestra{" "}
                        <strong>experiencia</strong> en gestión de propiedades para
                        optimizar los resultados de tu <strong>estructura</strong>. Nos
                        encargamos de <strong>maximizar los ingresos</strong> de tu
                        propiedad mientras tú te concentras en lo que realmente importa.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReviewClientsTrustBoxLanding;