import {Button, Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import CustomTextField from "../../../../components/customComponents/customTextField";
import {ChangeEvent, useEffect, useState} from "react";
import * as React from "react";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import {changeLeadHookData} from "../../../../hooks/leadsHook";
import {validationEmail} from "../../../../Shared/utils/validations";

interface props {
    lead: LeadInterface,
    setLead: Function,
    currentFunction: Function
}

const QuickContactEmailForm = ({lead, setLead, currentFunction}: props) => {

    const [sendButton, setSendButton] = useState(true);

    const availableSendButton = () => {
        setSendButton(validationEmail(lead.user.email));
    }

    const changeUserData = (key: string, value: string) => {
        changeLeadHookData(lead, setLead, key, value);
    }

    useEffect(() => {
        availableSendButton();
    }, [lead])

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={11} textAlign={'center'}>
                <Typography variant={'body1'} mb={5} color={'black'} fontSize={'2.3em'} fontWeight={600}
                            dangerouslySetInnerHTML={createMarkup('Introduzca su correo electrónico')}>
                </Typography>

                <Grid item xs={12} mb={5}>
                    <CustomTextField fullWidth
                                     type={'email'}
                                     required={true}
                                     value={lead.user.email}
                                     label={'Correo electrónico'}
                                     id="userContactFormEmailDetail"
                                     onChange={(e: ChangeEvent<HTMLInputElement>) => changeUserData('email', e.target.value)}
                    />
                </Grid>

                <Button className={'custom-winahost-button'} disabled={sendButton} onClick={() => currentFunction()}>
                                    <span style={{fontWeight: 'bold'}}>
                                        {'Solicita información'}
                                    </span>
                </Button>
            </Grid>
        </Grid>
    );
}

export default QuickContactEmailForm;
