import {Button, Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import * as React from "react";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import { validationFormPage } from "../../landingForm";
import {useState} from "react";
import {changePropertyHookData} from "../../../../hooks/leadsHook";

interface props {
    lead: LeadInterface,
    setLead: Function,
    validationFormPage: validationFormPage,
    setValidationFormPage: Function,
    handleChangePageAdd: Function
}

const TouristicLicenceLandingForm = ({lead, setLead, validationFormPage, setValidationFormPage, handleChangePageAdd}: props) => {

    const [buttonSelected, setButtonSelected] = useState<string>('');

    const handleSelectedButton = (value: string) => {
        setButtonSelected(value);
        changePropertyHookData(lead, setLead, 'licence', value);
        setValidationFormPage({...validationFormPage, licence: true})
        handleChangePageAdd();
    }

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12}>

                <Typography variant={'body1'} fontWeight={600} mb={3} mt={3} className={'landingFormTitles'}
                            dangerouslySetInnerHTML={createMarkup('¿Tienes licencia turística?')}/>

                <Grid container justifyContent={'center'} spacing={3}>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant={'outlined'}
                            style={(buttonSelected === 'yes' || lead.property.licence === 'yes') ? styles.activeButton : styles.disabledButton}
                            onClick={() => handleSelectedButton('yes')}
                        >
                            <Typography variant={'body1'} fontWeight={600} fontSize={'.9em'} p={1}
                                        style={(buttonSelected === 'yes' || lead.property.licence === 'yes') ? styles.activeText : styles.disabledText}
                                        dangerouslySetInnerHTML={createMarkup('Si, ya la tengo (o no es necesaria en mi ciudad)')}/>
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant={'outlined'}
                            style={(buttonSelected === 'no' || lead.property.licence === 'no') ? styles.activeButton : styles.disabledButton}
                            onClick={() => handleSelectedButton('no')}
                        >
                            <Typography variant={'body1'} fontWeight={600} fontSize={'.9em'} p={1}
                                        style={(buttonSelected === 'no' || lead.property.licence === 'no') ? styles.activeText : styles.disabledText}
                                        dangerouslySetInnerHTML={createMarkup('No, pero está en tramite o la pienso tramitar en breve')}/>
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant={'outlined'}
                            style={(buttonSelected === 'temporal' || lead.property.licence === 'temporal') ? styles.activeButton : styles.disabledButton}
                            onClick={() => handleSelectedButton('temporal')}
                        >
                            <Typography variant={'body1'} fontWeight={600} fontSize={'.9em'} p={1}
                                        style={(buttonSelected === 'temporal' || lead.property.licence === 'temporal') ? styles.activeText : styles.disabledText}
                                        dangerouslySetInnerHTML={createMarkup('Solo quiero alquiler temporal, no la necesito')}/>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const styles = {
    activeButton: {
        padding: '20px 0',
        backgroundColor: 'var(--main-winahost-color)',
        color: '#fff',
        borderRadius: 15
    },
    disabledButton: {
        padding: '20px 0',
        borderRadius: 15
    },
    activeText: {
        color: 'white'
    },
    disabledText: {
        color: 'var(--main-winahost-color)'
    }
}

export default TouristicLicenceLandingForm;
