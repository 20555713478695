import {Grid, Link, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {ES_CONTACT} from "../../../routes/constantsRoutes";
import {LIVINGROOM_IMAGE} from "../../../Shared/Constants/imageConstants";


const ManagementHeader = () => {

    const {t} = useTranslation('', {keyPrefix: 'servicePage.managementHeader'});

    const contactPath = ES_CONTACT;

    return (
        <Grid container justifyContent={'center'} mt={9}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={11} sm={9}>
                        <Typography variant="h1" className={'managementHeaderTitle'}  mb={5} fontWeight={700} color={'var(--main-winahost-color)'} textAlign={'center'}
                                    dangerouslySetInnerHTML={createMarkup(t('managementHeaderTitle'))}/>

                        <img src={LIVINGROOM_IMAGE}
                             alt=""
                             style={{width: '100%', borderRadius: 30}}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} className={'managementHeaderImageTitle'}>
                    <Grid item xs={11} sm={10} md={10} lg={10} xl={10}>
                        <Grid container justifyContent={'center'} mt={5}>
                            <Grid item xs={12}>
                                <Typography variant={'body1'} fontSize={'1.7em'} color={'black'} fontWeight={300} mb={5}
                                            dangerouslySetInnerHTML={createMarkup(t('managementHeaderBody'))}/>
                                <Link rel={'canonical'} key={'main_page'} href={`${contactPath}`}
                                      underline="none"
                                      className={'custom-winahost-button'}
                                >
                                    <span style={{fontWeight: 'bold'}}>
                                        {t('managementHeaderButton')}
                                    </span>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ManagementHeader;
