import {Grid, Typography} from "@mui/material";
import {Banner} from "../../../../components/banner/banner";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import {
    BOLSAMANIA_VECTOR_LOGO,
    EL_REFERENTE_LOGO,
    EXPANSION,
    FORBES_LOGO, LA_VANGUARDIA_VECTOR_LOGO, USA_NEWS_1CFD7A3AF221D19F10D25FAD0A75A724, WEBCAPITALRIESGO_300X150
} from "../../../../Shared/Constants/logosConstants";

const NewsReviewComponent = () => {

    const logos = [
        {
            'name': 'el-bolsamania-vector-logo',
            'path': BOLSAMANIA_VECTOR_LOGO,
            'alt': 'el-bolsamania-vector-logo',
            'height': '7rem',
        },
        {
            'name': 'el_referente_logo',
            'path': EL_REFERENTE_LOGO,
            'alt': 'el_referente_logo',
            'height': '7rem',
        },
        {
            'name': 'expansion',
            'path': EXPANSION,
            'alt': 'expansion',
            'height': '7rem',
        },
        {
            'name': 'la-vanguardia-vector-logo',
            'path': LA_VANGUARDIA_VECTOR_LOGO,
            'alt': 'la-vanguardia-vector-logo',
            'height': '7rem',
        },
        {
            'name': 'usa_news_1cfd7a3af221d19f10d25fad0a75a724a-vector-logo',
            'path': USA_NEWS_1CFD7A3AF221D19F10D25FAD0A75A724,
            'alt': 'usa_news_1cfd7a3af221d19f10d25fad0a75a724a-vector-logo',
            'height': '7rem',
        },
        {
            'name': 'webcapitalriesgo-300x150',
            'path': WEBCAPITALRIESGO_300X150,
            'alt': 'webcapitalriesgo-300x150',
            'height': '7rem',
        },
        {
            'name': 'forbes_logo',
            'path': FORBES_LOGO,
            'alt': 'forbes_logo',
            'height': '7rem',
        },
    ];

    return (
        <Grid container justifyContent={'center'} mt={5} mb={7}>
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                <Typography variant={'h2'}
                            fontSize={'1.3em'}
                            mb={3}
                            color={'var(--main-winahost-color)'}
                            fontWeight={600}
                            textAlign="center"
                            dangerouslySetInnerHTML={createMarkup('Winahost en medios de comunicación')}/>
                <Grid container justifyContent={'center'}>
                    <Banner objects={logos} type={'image'}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default NewsReviewComponent;
