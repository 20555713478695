import {Card, Grid, Typography} from "@mui/material";
import BaseAuthLayout from "../../Shared/layouts/baseAuthLayout";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import {WINAHOST_HORIZONTAL_LOGO} from "../../Shared/Constants/imageConstants";


interface props {
    children: JSX.Element | JSX.Element[]
}


const AuthLayout = ({children}: props) => {
    return (
        <BaseAuthLayout>
            <Grid container justifyContent={'center'} alignItems={'center'} style={{height: '100vh'}}>
                <Grid item xs={11} sm={11} md={5} lg={4} xl={3}>
                    <Card style={{
                        width: '100%',
                        borderRadius: 30,
                        textAlign: 'center'
                    }}>
                        <img src={WINAHOST_HORIZONTAL_LOGO} alt="winahost_transparent" style={{
                            width: '220px',
                            margin: '30px auto 20px auto',
                            textAlign: 'center'
                        }}/>
                        <Typography variant="body1" fontWeight={400} textAlign={'center'}
                                    color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup('Zona propietarios')}/>

                        <Typography variant="body1" mt={1} fontWeight={300} textAlign={'center'} fontSize={'.8em'}
                                    color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup('By <strong>Winahost</strong>')}/>

                        {children}

                    </Card>

                    <Typography variant="body1" mt={3} fontWeight={600} textAlign={'center'}
                                color={'var(--main-winahost-color)'}
                                dangerouslySetInnerHTML={createMarkup('Powered by <strong>Winahost</strong>')}/>

                    <Typography variant="body1" mt={1} fontWeight={300} textAlign={'center'} fontSize={'.8em'}
                                color={'var(--main-winahost-color)'}
                                dangerouslySetInnerHTML={createMarkup('Todos los derechos reservados &copy; 2024')}/>
                </Grid>
            </Grid>
        </BaseAuthLayout>
    );
}

export default AuthLayout;
