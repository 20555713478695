import {Grid, Link, Paper, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import {getMonthByDate} from "../../../Shared/utils/fomatDate";
import {Blog} from "../../../interfaces/BlogInterface";
import {useEffect, useState} from "react";
import BoxAutoAdjust from "../../../Shared/utils/boxAutoAdjust";

const MinimalistBlogSection = (blog: Blog) => {

    const date = new Date(blog.createdAt);
    const formatDate = getMonthByDate(date);

    const [boxHeight, setBoxHeight] = useState(0);

    const reAdjustBoxHeight = () => {
        const boxDataHeight = BoxAutoAdjust('itemSimpleBlogContent');
        setBoxHeight(boxDataHeight);
    }

    useEffect(() => {
        reAdjustBoxHeight();
    }, [])

    return (
        <Grid item xs={12} mb={1} className={'itemSimpleBlogContent'}>
            <Paper style={{
                height: (boxHeight) ? boxHeight : 'auto',
                margin: '0 10px',
                padding: 15,
                borderRadius: 30,
                backgroundColor: 'var(--main-winahost-gray-color)'
            }} elevation={5}>
                <img src={blog.picture}
                     alt={blog.path}
                     style={{width: '100%', height: '170px',borderRadius: 30, marginBottom: 25}}
                />
                <Typography variant={'body1'} fontSize={'.8em'} color={'black'}
                             mb={3}
                            dangerouslySetInnerHTML={createMarkup(formatDate)}/>
                <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                            fontWeight={600} mb={3}
                            dangerouslySetInnerHTML={createMarkup(blog.title)}/>
                <Link rel={'canonical'} href={'/blog' + blog.path} underline={'none'}>
                    <Typography variant={'body1'} fontSize={'1em'}
                                color={'var(--main-winahost-color)'}
                                 mb={3}
                                dangerouslySetInnerHTML={createMarkup('Leer más')}/>
                </Link>
            </Paper>
        </Grid>
    );
}

export default MinimalistBlogSection;
