import {Grid, Hidden, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {getBlogsData} from "../hooks/getBlogs";
import {Blog} from "../../../interfaces/BlogInterface";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import ResourceBlogSkeleton from "../../../components/skeletons/resourceBlogSkeleton";
import SimpleBlogSection from "./simpleBlogSection";
import HorizontalScroll from "../../../components/horizontalScroll/horizontalScroll";
import MinimalistBlogSection from "./minimalistBlogSection";
import {useTranslation} from "react-i18next";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {teal} from "@mui/material/colors";


const BlogsSection = () => {

    const {t} = useTranslation();

    const [blogs, setBlogs] = useState<Blog[] | null>(null);

    useEffect(() => {
        getBlogsData().then(res => setBlogs(res));
    }, [])

    if (!blogs) return <ResourceBlogSkeleton/>;

    const firstFourElements = blogs.slice(0, 4);
    const restOfElements = blogs.slice(4, blogs.length);

    return (
        <Grid container justifyContent={'center'} mt={5}>
            <Grid item xs={12} sm={8} md={11} lg={11} xl={11}>
                <Grid container justifyContent={'center'} spacing={4}>
                    <Grid item xs={11} textAlign={'center'}>
                        <Typography variant={'h1'} fontSize={'1.7em'} color={'var(--main-winahost-color)'} fontWeight={400}
                                    dangerouslySetInnerHTML={createMarkup('Aquí tienes algunos consejos e información que te pueden ser de utilidad')}/>
                    </Grid>

                    {firstFourElements.map((blog: Blog, index: number) => {
                        return (
                            <Grid key={index} item xs={11} sm={11} md={6} lg={3} xl={3}>
                                <SimpleBlogSection
                                    {...blog}
                                />
                            </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} mt={7}>
                <Grid item xs={10} sm={11} md={10} lg={8} xl={8}>
                    <Typography variant={'h1'} fontSize={'1.3em'} color={'var(--main-winahost-color)'}
                                fontWeight={600} mb={1} textAlign={'center'}
                                dangerouslySetInnerHTML={createMarkup('Lee un poco más sobre el alquiler turístico o de temporada')}/>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} spacing={2}>
                <Grid item xs={10} sm={12}>
                    <HorizontalScroll arrayList={restOfElements} component={MinimalistBlogSection} />

                    <Hidden smUp>
                        <Grid item xs={12} textAlign={'right'} mt={2}>
                            <Typography variant={'overline'} fontSize={'.6em'} color={'var(--main-winahost-color)'}
                                        fontWeight={600} mt={5} textAlign={'center'}
                                        dangerouslySetInnerHTML={createMarkup('Desliza para ver más blogs')}/>
                            <ArrowRightAltIcon fontSize={'large'} sx={{color: teal[600]}}/>
                        </Grid>
                    </Hidden>

                </Grid>
            </Grid>
        </Grid>
    );
}


export default BlogsSection;
