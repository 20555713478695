import BaseWithoutNavBarLayout from "../../../Shared/layouts/baseWithoutNavBarLayout";
import * as React from "react";
import SponsorsLogos from "../../../components/sponsors/sponsorsLogos";
import ManagementInOurHands from "../../serviceSection/sections/managementInOurHands";
import {useState} from "react";
import {LeadInterface} from "../../../interfaces/leadInterface";
import {leadFormContactNowBase} from "../../../Shared/baseData/leadBaseData";
import NewsReviewComponent from "../../mainSection/sections/components/newsReviewComponent";
import HeaderMainSectionContactNow from "./sections/headerMainSectionContactNow";
import BeginSectionContactNow from "./sections/beginSectionContactNow";


const ContactNowSection = () => {

    const [lead, setLead] = useState<LeadInterface>(leadFormContactNowBase);

    return (
        <BaseWithoutNavBarLayout>
            <HeaderMainSectionContactNow lead={lead} setLead={setLead}/>
            <SponsorsLogos logosColor={'winahost'}/>
            <ManagementInOurHands/>
            <NewsReviewComponent/>
            <BeginSectionContactNow lead={lead} setLead={setLead}/>
        </BaseWithoutNavBarLayout>
    );
}

export default ContactNowSection;
