import QuickContactContent from "./sections/quickContactContent";
import * as React from "react";


const QuickContactSection = () => {

    return (
        <QuickContactContent/>
    );
}

export default QuickContactSection;
