import {Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {t} from "i18next";
import {ControlProps, GroupBase, MenuProps} from "react-select";
import {Option} from "react-google-places-autocomplete/build/types";
import CustomTextField from "../../../components/customComponents/customTextField";
import {ChangeEvent, useEffect, useState} from "react";
import {LeadInterface} from "../../../interfaces/leadInterface";
import {changePropertyHookData} from "../../../hooks/leadsHook";
import {validationContactFormPage} from "../contact";

interface props {
    lead: LeadInterface,
    setLead: Function,
    validationFormPage: validationContactFormPage,
    setValidationFormPage: Function
}

const PropertyForm = ({lead, setLead, validationFormPage, setValidationFormPage}: props) => {

    const [buttons, setButtons] = useState({
        pool: false,
        parking: false,
        terrace: false
    });

    const changeLocationData = (key: string, value: any) => {
        changePropertyHookData(lead, setLead, key, value);
        setValidationFormPage({...validationFormPage, property: true})
    }

    const handleManagementExtras = (extra: string) => {
        if (lead.property.extras) {
            const indexValue = lead.property.extras.indexOf(extra);

            if (indexValue === -1) {
                lead.property.extras.push(extra);
            } else {
                lead.property.extras.splice(indexValue, 1);
            }
        } else {
            lead.property.extras = [extra];
        }

        _changeButtonsState(extra)
    }

    const _changeButtonsState = (key: string) => {
        setButtons({...buttons, [key]: !buttons[key]})
    }

    const changeContactData = (key: string, value: any) => {

        if ((key === 'bedrooms' || key === 'bathrooms' || key === 'accommodates') && value < 0) {
            value = 0;
        }
        changePropertyHookData(lead, setLead, key, value);
    }

    const checkIfContactHasExtras = () => {
        if (lead.property.extras) {
            setButtons(prevButtons => {
                // @ts-ignore
                return lead.property.extras.reduce((updatedButtons, extra) => {
                    return {...updatedButtons, [extra]: true};
                }, {...prevButtons});
            });
        }
    }

    useEffect(() => {
        checkIfContactHasExtras();
    }, []);

    return (
        <Grid container justifyContent={'left'} mt={1} spacing={3}>
            <Grid item xs={12}>
                <Typography variant={'body1'} fontWeight={400} mb={3} className={'contactFormTitle'}
                            dangerouslySetInnerHTML={createMarkup('Necesitamos más información sobre tu propiedad')}/>
                <Typography variant={'body1'} mb={3} className={'contactFormSubtitle'}
                            dangerouslySetInnerHTML={createMarkup('Esto nos ayudará a compararla con apartamentos turísticos similares en tu ubicación. Rellena los datos y te contactaremos para que tengas un informe de tus ingresos más preciso y conozcas como te podemos ayudar. Será menos de un minuto.')}/>
            </Grid>
            <Grid item xs={12}>
                <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                    apiOptions={{
                        region: 'es'
                    }}
                    selectProps={{
                        defaultInputValue: lead.property.address,
                        placeholder: t('mainPage.leadForm.propertyForm.propertyFormInput'),
                        onChange: (e: any) => changeLocationData('address', e.label),
                        instanceId: 'react-select-3-live-region',
                        className: 'selectCustomStyled',
                        classNames: {
                            control: (props: ControlProps<Option, false, GroupBase<Option>>) => {
                                return 'selectInputCustomStyled'
                            },
                            menu: (props: MenuProps<Option, false, GroupBase<Option>>) => {
                                return 'selectMenuCustomStyled';
                            }
                        }
                    }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <CustomTextField fullWidth
                                 type={'number'}
                                 aria-valuemin={0}
                                 value={lead.property.bedrooms}
                                 label={t('mainPage.leadForm.propertyForm.propertyFormRooms')}
                                 id="propertyDetailRoomContactForm"
                                 onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('bedrooms', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <CustomTextField fullWidth
                                 type={'number'}
                                 value={lead.property.accommodates}
                                 label={t('mainPage.leadForm.propertyForm.propertyFormPax')}
                                 id="propertyDetailPaxContactForm"
                                 onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('accommodates', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <CustomTextField fullWidth
                                 type={'number'}
                                 value={lead.property.bathrooms}
                                 label={t('mainPage.leadForm.propertyForm.propertyFormBathrooms')}
                                 id="propertyDetailBathroomsContactForm"
                                 onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('bathrooms', e.target.value)}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">¿Cuál es la disponibilidad de tu propiedad para alquilar?</FormLabel>
                    <RadioGroup aria-label="gender" name="availability" value={lead.property.availability} onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('availability', e.target.value)}>
                        <FormControlLabel value="fulltime" control={<Radio/>}
                                          label="Tiempo completo (9-12 meses por año)"/>
                        <FormControlLabel value="halftime" control={<Radio/>}
                                          label="Tiempo parcial (menos de 9 meses)"/>
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
                <Typography variant={'body1'} fontSize={'1em'} color={'black'}
                             mb={2}
                            dangerouslySetInnerHTML={createMarkup('¿Tienes alguno de estos extras? (Opcional)')}/>
                <Grid container justifyContent={'left'} spacing={2} columns={8}>
                    <Grid item xs={12} sm={2}>
                        <Button variant={`${(buttons.pool) ? 'contained': 'outlined'}`} color="primary"
                                onClick={() => handleManagementExtras('pool')}>{'Piscina'}</Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button variant={`${(buttons.parking) ? 'contained': 'outlined'}`} color="primary"
                                onClick={() => handleManagementExtras('parking')}>{'Parking'}</Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button variant={`${(buttons.terrace) ? 'contained': 'outlined'}`} color="primary"
                                onClick={() => handleManagementExtras('terrace')}>{'Terraza'}</Button>
                    </Grid>
                </Grid>
            </Grid>


            <Grid item xs={12} md={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">¿Eres el dueño de esta propiedad?</FormLabel>
                    <RadioGroup aria-label="owner" name="owner" value={lead.property.owner}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('owner', e.target.value)}>
                        <FormControlLabel value="yes" control={<Radio/>} label="Sí"/>
                        <FormControlLabel value="no" control={<Radio/>} label="No"/>
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">¿Estas pensando en contratar una empresa de gestión para alquilar tu vivienda?</FormLabel>
                    <RadioGroup aria-label="hire" name="hire" value={lead.property.hire}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('hire', e.target.value)}>
                        <FormControlLabel value="yes" control={<Radio/>} label="Sí"/>
                        <FormControlLabel value="no" control={<Radio/>} label="No"/>
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">¿Tienes tramitada la licencia necesaria para alquilar en tu ciudad?</FormLabel>
                    <RadioGroup aria-label="licence" name="licence" value={lead.property.licence}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => changeContactData('licence', e.target.value)}>
                        <FormControlLabel value="yes" control={<Radio/>} label="Sí"/>
                        <FormControlLabel value="no" control={<Radio/>} label="No, aunque tengo intención de tramitarla"/>
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default PropertyForm;
