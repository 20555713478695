import {Grid, Link, Paper, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import {useTranslation} from "react-i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {ES_PRICES, ES_PROFESSIONAL} from "../../../routes/constantsRoutes";



const ProfessionalCard = ({priceBoxHeight}: { priceBoxHeight: number }) => {

    const {t} = useTranslation();

    const textProperties = [
        'Consultoría de expansión para inversores inmobiliarios',
        'Outsorcing de alquiler turístico para inmobiliarias',
        'Gestión de propiedades de Edificios',
        'Gestión de propiedades de Promociones Inmobiliarias',
        'Gestión de propiedades de Sociedades Patrimoniales',
        'Gestión de propiedades de Inmobiliarias',
        'Análisis de Rentabilidad de Propiedades'
    ];

    return (
        <Grid item xs={12} sm={12} md={4}>
            <Paper
                className={'priceBoxContent'}
                style={{
                    backgroundColor: 'rgba(113,170,170,0.4)',
                    borderRadius: 30,
                    padding: '0 40px 20px 40px',
                    height: `${priceBoxHeight === 0 ? 'auto' : priceBoxHeight + 'px'}`

                }} elevation={5}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12}>
                        <Typography variant={'body1'} fontSize={'1.7em'} mt={3} fontWeight={500}
                                    dangerouslySetInnerHTML={createMarkup('Profesional')}/>
                        <Typography variant={'body1'} fontSize={'1.7em'} mt={3} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup('Plan personalizado')}/>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography variant={'body1'} fontSize={'.8em'} textAlign={'justify'}
                                    dangerouslySetInnerHTML={createMarkup('Si eres un profesional o multipropietario contacta con nosotros, te haremos un plan a medida. Ofrecemos servicios vinculados al alquiler turístico o de temporada como:')}/>

                    </Grid>
                    <Grid item xs={12} mt={2}>
                        {textProperties.map((property: string, index: number) => {
                            return (
                                <Grid key={index} container justifyContent={'center'} mt={1.5}>
                                    <Grid item xs={1}>
                                        <CheckCircleOutlineIcon/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography key={index} variant={'body1'}
                                                    dangerouslySetInnerHTML={createMarkup(property)}/>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid item xs={12} mt={5} mb={3}>
                        <Link rel={'canonical'} key={'main_page'} href={ES_PRICES + ES_PROFESSIONAL}
                              underline="none"
                              className={'custom-winahost-button priceCardButton'}
                        >
                                        <span style={{fontWeight: 'bold'}}>
                                            {'Empezar'}
                                        </span>
                        </Link>
                    </Grid>
                </Grid>

            </Paper>
        </Grid>
    );
}

export default ProfessionalCard;
