import {useEffect, useState} from "react";

const BoxAutoAdjust = (className: string): number => {
    const boxContents = document.getElementsByClassName(className);

    let maxHeight = 0;
    for (let i = 0; i < boxContents.length; i++) {
        let height = boxContents[i].clientHeight;
        maxHeight = Math.max(maxHeight, height);
    }

    return maxHeight;
}

export default BoxAutoAdjust;
