import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";


const PointsSection = () => {

    const {t} = useTranslation('', {keyPrefix: 'servicePage.pointsSection'});

    const points = [
        {
            number: 1,
            text: t('pointsSectionOne')
        },
        {
            number: 2,
            text: t('pointsSectionTwo')
        },
        {
            number: 3,
            text: t('pointsSectionThree')
        }
    ];

    return (
        <Grid container justifyContent={'center'} mt={10} mb={5}>
            <Grid item xs={11} textAlign={'center'}>
                <Grid container spacing={2} justifyContent={'center'}>
                    <Grid item xs={12} md={8} textAlign={'center'}>
                        <Typography variant="h2" fontSize={'1.7em'} mb={5} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup(t('pointsSectionTitle'))}/>
                        <Typography variant="body1" mb={5}
                                    dangerouslySetInnerHTML={createMarkup(t('pointsSectionSubTitle'))}/>
                        <Grid container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              spacing={4}>
                            {points.map((point, index) => {

                                return (
                                    <Grid key={index} item xs={12} md={4}>
                                        <Grid justifyContent={'center'} textAlign={'center'}>
                                            <RoundRadiusNumber number={point.number}/>
                                            <Typography variant={'body1'} mt={3} mb={1} color={'black'}
                                                        fontWeight={600}
                                                        dangerouslySetInnerHTML={createMarkup(point.text)}/>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const RoundRadiusNumber = ({number}: { number: number }) => {
    return (
        <Grid style={styles.roundNumber}>
            <span style={styles.numberStyle}>
                {number}
            </span>
        </Grid>
    );
}

const styles = {
    roundNumber: {
        backgroundColor: 'var(--main-winahost-color)',
        borderRadius: 100,
        padding: 40,
        width: 130,
        margin: '0 auto'
    },
    numberStyle: {
        color: 'white',
        fontSize: '2em',
        fontWeight: 900,
        fontStretch: '200%'
    }
}

export default PointsSection;
