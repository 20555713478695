import { useRef } from "react";
import { Banner } from "../../components/banner/banner";
import BaseLayout from "../../Shared/layouts/baseLayout";
import ReviewClientsTrustBoxLanding from "../mainSection/sections/reviewClientsTrustBoxLanding";
import ManagementHeaderAt from "../serviceSection/sections/managementHeaderAt";
import PropietarioSolution1 from "../serviceSection/sections/propietarioSolution1";
import NewsReviewComponent from "../mainSection/sections/components/newsReviewComponent";
import ReviewClientsTrustBoxAt from "../mainSection/sections/components/reviewClientsTrustBoxAt";
import Property360SpaceLanding from "../serviceSection/sections/property360SpaceLanding";
import FormAt from "../serviceSection/sections/formAt";
import FormularioAt from "./formularioAt";

const ContactAT = () => {
    const leadFormRef = useRef<HTMLDivElement | null>(null);

    const bannerObjects = [
        { path: "https://winahost.s3.eu-west-1.amazonaws.com/images/logos/airbnb_winahost.svg", alt: "airbnb" },
        { path: "https://winahost.s3.eu-west-1.amazonaws.com/images/logos/tripadvisor_winahost.svg", alt: "tripadvisor" },
        { path: "https://winahost.s3.eu-west-1.amazonaws.com/images/logos/locasun_winahost.svg", alt: "locasun" },
        { path: "https://winahost.s3.eu-west-1.amazonaws.com/images/logos/holidu_winahost.svg", alt: "holidu" },
        { path: "https://winahost.s3.eu-west-1.amazonaws.com/images/logos/booking_winahost.svg", alt: "booking" },
        { path: "https://winahost.s3.eu-west-1.amazonaws.com/images/logos/vrbo_winahost.svg", alt: "vrbo" },
    ];

    return (
        <BaseLayout>
            <ManagementHeaderAt leadFormRef={leadFormRef} />
            <PropietarioSolution1 />
            <Banner objects={bannerObjects} type="image" />
            <ReviewClientsTrustBoxLanding />
            <Property360SpaceLanding />
            <FormAt />
            <div ref={leadFormRef}>
             <FormularioAt />
            </div>
             <NewsReviewComponent />
            <ReviewClientsTrustBoxAt />
        </BaseLayout>
    );
};

export default ContactAT;