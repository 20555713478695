import { Grid, Typography } from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";


const Statistics = () => {

    const {t} = useTranslation();

    return (
        <Grid container sx={{flexGrow: 1}} spacing={4} mt={1} mb={5} justifyContent={'center'} >
            <Grid item justifyContent={'center'} xs={10} sm={10}>
                <Grid container justifyContent={'center'} style={{backgroundColor: 'var(--main-winahost-gray-color)', borderRadius: 30}}>
                    <Grid item xs={11.5} md={5} justifyContent={'center'} alignItems={'center'} pt={5} style={{textAlign:'center', margin: 'auto'}}>
                        <Typography variant={'body1'} fontSize={'1.7em'} mb={5} color={'var(--main-winahost-color)'} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup(t('pricesPage.statistics.statisticsWorkWithUs'))}/>
                    </Grid>
                    <Grid item xs={12} md={2} justifyContent={'center'} alignItems={'center'} className={'statisticsWorkWithUs'} style={{textAlign:'center'}}>
                        <span style={{fontWeight: 'bold', fontSize: '1.3em', color:'var(--main-winahost-color)'}}>96%</span>
                        <Typography variant={'body1'} mb={5} color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup(t('pricesPage.statistics.statisticsSatisfiedOwners'))}/>
                    </Grid>
                    <Grid item xs={12} md={2} justifyContent={'center'} alignItems={'center'} className={'statisticsWorkWithUs'} style={{textAlign:'center'}}>
                        <span style={{fontWeight: 'bold', fontSize: '1.3em', color:'var(--main-winahost-color)'}}>5+</span>
                        <Typography variant={'body1'} mb={5} color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup(t('pricesPage.statistics.statisticsYearsOfExperience'))}/>
                    </Grid>
                    <Grid item xs={12} md={2} justifyContent={'center'} alignItems={'center'} className={'statisticsWorkWithUs'} style={{textAlign:'center'}}>
                        <span style={{fontWeight: 'bold', fontSize: '1.3em', color:'var(--main-winahost-color)'}}>4.6/5</span>
                        <Typography variant={'body1'} mb={5} color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup(t('pricesPage.statistics.statisticsGuestsPoints'))}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Statistics;
