import React from 'react';
import {Grid} from "@mui/material";

import './../../Styles/globals.css'
import './../../Styles/drawerCss.css';
import './../../Styles/footerCss.css';

import Footer from "../../components/footer/footer";
import WhatsappLogo from "../sharedTexts/WhatsappLogo";
import NavBarWithoutButtons from "../../components/navbar/navBarWithoutButtons";


interface props {
    children: JSX.Element | JSX.Element[]
}

const BaseWithoutNavBarLayout = ({children}: props) => {

    return (
        <Grid container>
            <NavBarWithoutButtons lang={'es'}/>
            <Grid container mt={'65.5px'} justifyContent="center">
                <Grid item xs={12} className={'MobileScreenLayout'}>
                    {children}
                    <WhatsappLogo/>
                </Grid>
            </Grid>
            <Footer/>
        </Grid>
    );
}

export default BaseWithoutNavBarLayout;
