import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { motion } from "framer-motion";

const FormAt = () => {
  const stepVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: (custom: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: custom * 0.3,
        duration: 0.6,
        type: "spring",
        stiffness: 100
      }
    })
  };

  return (
    <Box
      sx={{
        fontFamily: "'Poppins', sans-serif",
        textAlign: "center",
        px: { xs: 1, sm: 2 },
        py: { xs: 1, sm: 2 },
        minHeight: "90vh",
        height: "auto",
        backgroundColor: "#f9f9f9",
        overflow: "hidden",
        backgroundImage: "url('https://s3.eu-west-1.amazonaws.com/winahost.com/images/idea2.jpg')",
        backgroundSize: "cover", 
        backgroundPosition: "center", 
        backgroundAttachment: "scroll",
        position: "relative",
        backgroundRepeat: "no-repeat"
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          zIndex: 1
        }}
      />

      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        style={{ position: "relative", zIndex: 2 }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: "1.5rem", sm: "2rem" },
            fontWeight: 700,
            mb: 4,
            color: "#367E7F",
            textAlign: "center",
            textShadow: "1px 1px 2px rgba(255,255,255,0.8)",
            margin: "50px",
          }}
        >
          Empezar con Winahost es muy sencillo
        </Typography>
      </motion.div>

      <Box
        sx={{
          maxWidth: "800px",
          margin: "0 auto",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          zIndex: 2,
          px: { xs: 2, sm: 0 }
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "4px",
            height: "100%",
            backgroundColor: "#367E7F",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
          }}
        />

        <Box
          sx={{
            position: "absolute",
            left: "50%",
            width: "20px",
            height: "100%",
            transform: "translateX(-50%)",
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "#FFFFFF",
              border: "4px solid #367E7F",
              borderRadius: "50%",
              top: "0%",
              position: "absolute"
            }}
          />
          <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "#FFFFFF",
              border: "4px solid #367E7F",
              borderRadius: "50%",
              top: "50%",
              position: "absolute",
              transform: "translateY(-50%)"
            }}
          />
          <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "#FFFFFF",
              border: "4px solid #367E7F",
              borderRadius: "50%",
              bottom: "0%",
              position: "absolute"
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            mb: 1
          }}
        >
          <motion.div
            custom={0}
            initial="hidden"
            animate="visible"
            variants={stepVariants}
            style={{ 
              width: "45%", 
              marginLeft: "auto",
              marginBottom: "1rem" // Added margin for mobile spacing
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                border: "2px solid #367E7F",
                borderRadius: "20px",
                p: 1,
                ml: { xs: 0, sm: 1 },
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                textAlign: "left"
              }}
            >
              <Typography
                variant="h6"
                fontWeight={700}
                color="#367E7F"
                mb={1}
                textAlign="center"
                sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
              >
                Paso 1
              </Typography>
              <Typography 
                variant="body1"
                sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
              >
                Rellena el formulario y nos pondremos en contacto contigo
              </Typography>
            </Box>
          </motion.div>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            mb: 1
          }}
        >
          <motion.div
            custom={1}
            initial="hidden"
            animate="visible"
            variants={stepVariants}
            style={{ 
              width: "45%", 
              marginRight: "auto",
              marginBottom: "1rem" // Added margin for mobile spacing
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                border: "2px solid #367E7F",
                borderRadius: "20px",
                p: 1,
                mr: { xs: 0, sm: 1 },
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                textAlign: "left"
              }}
            >
              <Typography
                variant="h6"
                fontWeight={700}
                color="#367E7F"
                mb={1}
                textAlign="center"
                sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
              >
                Paso 2
              </Typography>
              <Typography 
                variant="body1"
                sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
              >
               Preparamos todo para empezar a gestionar tus activos
              </Typography>
            </Box>
          </motion.div>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
          }}
        >
          <motion.div
            custom={2}
            initial="hidden"
            animate="visible"
            variants={stepVariants}
            style={{ 
              width: "45%", 
              marginLeft: "auto",
              marginBottom: "1rem" // Added margin for mobile spacing
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                border: "2px solid #367E7F",
                borderRadius: "20px",
                p: 1,
                ml: { xs: 0, sm: 1 },
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                textAlign: "left"
              }}
            >
              <Typography
                variant="h6"
                fontWeight={700}
                color="#367E7F"
                mb={1}
                textAlign="center"
                sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
              >
                Paso 3
              </Typography>
              <Typography 
                variant="body1"
                sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
              >
               Estás listo para formar parte de la familia Winahost
              </Typography>
            </Box>
          </motion.div>
        </Box>
      </Box>
    </Box>
  );
};

export default FormAt;