import React from 'react';
import { Grid, Typography, Button, useTheme, useMediaQuery } from "@mui/material";
import AnimatedText from '../../AtSection/AnimatedText';

export const LANDING_IMAGE = 'https://winahost.s3.eu-west-1.amazonaws.com/images/landing-at.jpg';

const ManagementHeaderAt = ({ leadFormRef }: { leadFormRef: React.RefObject<HTMLElement> }) => {
    const fullText = "  Edificios, Activos Inmobiliarios, Conjuntos residenciales.";
    const sendButton = false;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleScrollToCalculator = () => {
        if (leadFormRef.current) {
            leadFormRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    return (
        <Grid
            container
            justifyContent="center"
            mt={10}
            style={{
                fontFamily: "'Poppins', sans-serif",
                position: 'relative',
                overflow: 'hidden',
                borderRadius: '20px',
                maxWidth: 'auto',
                margin: '0 auto',
            }}
        >
            <Grid
                item
                xs={12}
                style={{
                    backgroundImage: `url(${LANDING_IMAGE})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center bottom',
                    height: isMobile ? '500px' : '850px',
                    color: 'black',
                    textAlign: 'center',
                    padding: isMobile ? '50px 10px' : '100px 10px',
                    fontFamily: "'Poppins', sans-serif",
                }}
            >
                <Typography
                    variant={isMobile ? "h6" : "h5"}
                    style={{
                        color: '#367E7F',
                        marginBottom: '15px',
                        padding: '0 10px',
                        fontWeight: 'bold',
                        fontFamily: "'Poppins', sans-serif",
                    }}
                >
                    ¿Tienes un edificio?
                </Typography>

                <Typography
                    variant={isMobile ? "h4" : "h2"} 
                    style={{
                        marginBottom: '5px',
                        padding: '5px',
                        fontWeight: 'bold',
                        fontFamily: "'Poppins', sans-serif",
                    }}
                >
                    Gestión 360 de:
                </Typography>

                <Typography
                    variant={isMobile ? "h6" : "h4"} // Tamaño adaptativo
                    style={{ marginTop: '10px', fontFamily: "'Poppins', sans-serif" }}
                >
                    <AnimatedText text={fullText || ''} />
                </Typography>

                <Grid item style={{ marginTop: '40px' }}>
                    <Button
                        onClick={handleScrollToCalculator}
                        className={sendButton ? 'custom-winahost-button-disabled' : 'custom-winahost-button'}
                        disabled={sendButton}
                        style={{
                            backgroundColor: sendButton ? 'red' : 'var(--main-winahost-color)',
                            color: sendButton ? 'white' : 'inherit',
                            borderRadius: '5px',
                            padding: isMobile ? '8px' : '10px',
                            width: isMobile ? '150px' : '200px',
                            fontFamily: "'Poppins', sans-serif",
                        }}
                    >
                        <span style={{ fontWeight: "bold", fontFamily: "'Poppins', sans-serif" }}>{"contacta ahora"}</span>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ManagementHeaderAt;