import {Grid, Typography} from "@mui/material";
import BaseLayout from "../../../../Shared/layouts/baseLayout";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import ScreenSizeResource from "../../../../Shared/utils/screenSizeResource";
import {
    APP_HUESPEDES_V2,
    INTELLIGENCEARTIFICIAL,
    LAPTOP_MANAGEMENT,
    MOBILE_PLATFORM, PROVEEDORES
} from "../../../../Shared/Constants/imageConstants";

const Technology = () => {

    const {t} = useTranslation('', {keyPrefix: 'servicePage.technology'});

    const {width} = ScreenSizeResource();

    let rowDirection: 'row' | 'row-reverse' = 'row';
    if (width > 500) {
        rowDirection = 'row-reverse';
    }

    return (
        <BaseLayout>
            <Grid container justifyContent={'center'} mt={5} mb={5} >
                <Grid item xs={11} sm={11} md={11} lg={9} xl={8}>
                    <Typography variant={'h2'}
                                fontSize={'1.7em'}
                                mb={3}
                                mt={5}
                                color={'black'}
                                fontWeight={600}
                                textAlign="center"
                                dangerouslySetInnerHTML={createMarkup(t('title'))}/>

                    <Grid container justifyContent={'center'} mt={5} spacing={3}>
                        <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                            <Typography variant={'h2'}
                                        fontSize={'1.7em'}
                                        mb={5}
                                        color={'black'}
                                        fontWeight={600}
                                        dangerouslySetInnerHTML={createMarkup(t('bigdata.title'))}/>

                            <Typography variant={'body1'} mb={3} dangerouslySetInnerHTML={createMarkup(t('bigdata.body1'))}/>
                            <Typography variant={'body1'} mb={3} dangerouslySetInnerHTML={createMarkup(t('bigdata.body2'))}/>
                            <Typography variant={'body1'} mb={3} dangerouslySetInnerHTML={createMarkup(t('bigdata.body3'))}/>
                        </Grid>
                        <Grid item xs={11} sm={11} md={6} lg={6} xl={6} style={styles}>
                            <img src={LAPTOP_MANAGEMENT} alt={"laptop_management"} width={'90%'}/>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'center'} direction={rowDirection} mt={5} spacing={3}>
                        <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                            <Typography variant={'h2'}
                                        fontSize={'1.7em'}
                                        mb={5}
                                        color={'black'}
                                        fontWeight={600}
                                        dangerouslySetInnerHTML={createMarkup(t('platform.title'))}/>

                            <Typography variant={'body1'} mb={3} dangerouslySetInnerHTML={createMarkup(t('platform.body1'))}/>
                            <ul>
                                <li>
                                    <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup(t('platform.bodyPoints.first'))}/>
                                </li>
                                <li>
                                    <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup(t('platform.bodyPoints.second'))}/>
                                </li>
                                <li>
                                    <Typography variant={'body1'} dangerouslySetInnerHTML={createMarkup(t('platform.bodyPoints.third'))}/>
                                </li>
                            </ul>
                            <Typography variant={'body1'} mb={3} dangerouslySetInnerHTML={createMarkup(t('platform.body2'))}/>
                        </Grid>
                        <Grid item xs={11} sm={11} md={6} lg={6} xl={6} style={styles}>
                            <img src={MOBILE_PLATFORM} alt={"mobile-platform"} width={'70%'}/>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'center'} mt={5} spacing={3}>
                        <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                            <Typography variant={'h2'}
                                        fontSize={'1.7em'}
                                        mb={5}
                                        color={'black'}
                                        fontWeight={600}
                                        dangerouslySetInnerHTML={createMarkup(t('provider.title'))}/>

                            <Typography variant={'body1'} mb={3} dangerouslySetInnerHTML={createMarkup(t('provider.body1'))}/>
                        </Grid>
                        <Grid item xs={11} sm={11} md={6} lg={6} xl={6} style={styles}>
                            <img src={PROVEEDORES} alt={"laptop_management"} width={'100%'}/>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'center'} direction={rowDirection} mt={5} spacing={3}>
                        <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                            <Typography variant={'h2'}
                                        fontSize={'1.7em'}
                                        mb={5}
                                        color={'black'}
                                        fontWeight={600}
                                        dangerouslySetInnerHTML={createMarkup(t('guest.title'))}/>

                            <Typography variant={'body1'} mb={3} dangerouslySetInnerHTML={createMarkup(t('guest.body1'))}/>
                        </Grid>
                        <Grid item xs={11} sm={11} md={6} lg={6} xl={6} style={styles}>
                            <img src={APP_HUESPEDES_V2} alt={"mobile-platform"} width={'70%'}/>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'center'} mt={5} spacing={3}>
                        <Grid item xs={11} sm={11} md={6} lg={6} xl={6}>
                            <Typography variant={'h2'}
                                        fontSize={'1.7em'}
                                        mb={5}
                                        color={'black'}
                                        fontWeight={600}
                                        dangerouslySetInnerHTML={createMarkup(t('intelligenceArtificial.title'))}/>

                            <Typography variant={'body1'} mb={3} dangerouslySetInnerHTML={createMarkup(t('intelligenceArtificial.body1'))}/>
                        </Grid>
                        <Grid item xs={11} sm={11} md={6} lg={6} xl={6} style={styles}>
                            <img src={INTELLIGENCEARTIFICIAL} alt={"laptop_management"} width={'90%'}/>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </BaseLayout>
    );
}

const styles = {
    margin: 'auto auto'
}

export default Technology;
