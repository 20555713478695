export interface newReviewInterface {
    title: string;
    name: string;
    description: string;
    rating: number;
  }
  
  const getReviewCollection = (): newReviewInterface[] => {
    const reviewCollection: newReviewInterface[] = [
      {
          description: "Gracias a la tecnología avanzada de Winahost, nuestras reservas se han optimizado al 100%. Sus sistemas de gestión automática de precios y la asistencia en la licencia AT nos permitieron aumentar nuestros ingresos un 40%",
          name: "Ana R.",
          title: "",
          rating: 5
      },
      {
          description: "Lo que más nos impresionó de Winahost fue su capacidad para analizar la demanda del mercado y ajustar los precios en tiempo real. Además, el trámite de la licencia AT fue un proceso sencillo gracias a su equipo.",
          name: "José P.",
          title: "",
          rating: 5
      },
      {
        description: "Winahost Nos ayuda mucho. Gracias a sus ajustes de precios en tiempo real y a su asesoría continua, nuestras propiedades alcanzaron un nivel de rentabilidad que nunca habíamos visto.",
        name: "Xavier O.",
        title: "",
        rating: 5
    },
    ];
  
    return reviewCollection;
  };
  
  export default getReviewCollection;  