import {Grid} from "@mui/material";
import '../../../../Styles/mainPage.css';
import {LeadInterface} from "../../../../interfaces/leadInterface";
import {formatLeadMergeData} from "../../../../hooks/leadsHook";
import {upsertHubspotContact} from "../../../../Shared/hooks/upsertHubspotContact";
import {useState} from "react";
import HeaderMainForm from "./headerMainForm";
import CongratulationsContactNowForm from "../forms/congratulationsContactNowForm";
import {gtagLandingContactNowConversion, metaLeadTrack} from "../../../../hooks/socialMeditionTags";
import {DISENO_INTERIOR_CASA_CONTEMPORANEA} from "../../../../Shared/Constants/imageConstants";

interface props {
    lead: LeadInterface,
    setLead: Function
}

const HeaderMainSectionContactNow = ({lead, setLead}: props) => {

    const [page, setPage] = useState(1);

    const sendContact = () => {
        addPage();
        handleSendLeadToHubspot();
    }

    const addPage = () => {
        setPage(page + 1);
    }

    const handleSendLeadToHubspot = () => {

        metaLeadTrack();
        gtagLandingContactNowConversion();

        const leadMergeData = formatLeadMergeData(lead);
        upsertHubspotContact(leadMergeData);
    }

    const showStepperForm = () => {
        switch (page) {
            case 1:
                return <HeaderMainForm lead={lead} setLead={setLead} currentFunction={sendContact}/>;
            case 2:
                return <CongratulationsContactNowForm/>;
            default:
                return null;
        }
    }

    return (
        <Grid container justifyContent={'center'} mt={5} className={'calculatorDegree'}>
            <Grid item xs={12} sm={11} md={11} lg={10} xl={9}>
                <Grid container justifyContent={'center'} spacing={4}>
                    <Grid item xs={11} sm={11} md={6} lg={7} xl={6} className={'mainPageTitle'}>
                        <Grid container justifyContent={'center'} mt={5} alignItems={'center'}>
                            {showStepperForm()}
                        </Grid>
                    </Grid>
                    <Grid item xs={11} sm={11} md={6} lg={5} xl={6} mt={5} textAlign={'center'}>
                        <img src={DISENO_INTERIOR_CASA_CONTEMPORANEA}
                             alt={"diseno-interior-casa-contemporanea"}
                             className={'imagePageTitle'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default HeaderMainSectionContactNow;
