import {Grid} from "@mui/material";
import * as React from "react";
import OnlineCard from "./onlineCard";
import PremiumCard from "./premiumCard";
import ProfessionalCard from "./professionalCard";

interface props {
    priceBoxHeight: number
}

const PricesSection = ({priceBoxHeight}: props) => {

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={11} sm={11} md={11} lg={11} xl={9}>
                <Grid container justifyContent={'center'} spacing={3}>

                    <OnlineCard priceBoxHeight={priceBoxHeight} />
                    <PremiumCard priceBoxHeight={priceBoxHeight} />
                    <ProfessionalCard priceBoxHeight={priceBoxHeight} />

                </Grid>
            </Grid>
        </Grid>
    );
}

export default PricesSection;
