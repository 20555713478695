import {Grid} from "@mui/material";

const DiscoverHowWeCalculated = () => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid xs={11}>

                <Grid container justifyContent={'center'}>
                    <Grid xs={12}>
                        <div>
                            <p>
                                Nuestra calculadora para estimar cuánto podrías ganar, analiza los datos de las reservas de los últimos 12 meses en anuncios similares al tuyo, que elegimos teniendo en cuenta la información que has proporcionado sobre tu alojamiento. Si indicas la dirección, la estimación que obtendrás estará basada específicamente en anuncios de espacios cercanos a tu ubicación.
                            </p>
                            <p>
                                Basándonos en tu selección, podemos saber los ingresos medios por noche, la ocupación media del ultimo año y lo multiplicamos por la cantidad de noches que podrías recibir huéspedes también desde nuestro simulador. También calculamos el promedio mensual de noches reservadas en tu zona. Los ingresos por noche son el precio que establece el anfitrión menos la <i>comisión de servicio para anfitriones que cobra la plataforma de alquiler, como Airbnb</i> u otras, sin restar impuestos o gastos relativos al hospedaje.
                            </p>
                            <p>
                                Tu rentabilidad e ingresos reales dependerán de varios factores, como el atractivo de tu anuncio, tu disponibilidad, tus impuestos locales, el mantenimiento de tu hogar, tus precios y la demanda que haya en el próximo año en tu zona. La legislación local también podría influir en el desarrollo de tu actividad en los próximos años.
                            </p>
                            <p>
                                Los cálculos de ingresos y rentabilidad son simplemente una estimación y no constituyen una tasación oficial del valor de la propiedad.
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DiscoverHowWeCalculated;
