import BaseLayout from "../../Shared/layouts/baseLayout";
import BlogsSection from "./sections/blogsSection";
import Faq from "./faq/faq";


const Resources = () => {

    return (
        <BaseLayout>
            <BlogsSection />
            <Faq />
        </BaseLayout>
    );
}

export default Resources;
