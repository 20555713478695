import {Grid, Button} from "@mui/material";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {ChangeEvent, useState} from "react";
import {Option} from "react-google-places-autocomplete/build/types";
import {ControlProps, GroupBase, MenuProps} from "react-select";
import CustomTextField from "../../../../components/customComponents/customTextField";
import {useTranslation} from "react-i18next";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import {changePropertyHookData} from "../../../../hooks/leadsHook";

interface props {
    lead: LeadInterface,
    setLead: Function,
    currentFormPage: number,
    setCurrentFormPage: Function,
}

const PropertyForm = (
    {
        lead,
        setLead,
        currentFormPage,
        setCurrentFormPage,
    }: props) => {

    const {t} = useTranslation('', {keyPrefix: 'mainPage.leadForm.propertyForm'});

    const [googleOptions, setGoogleOptions] = useState<string[]>([]);

    const validationBase = {
        location: {
            error: false,
            classReference: 'selectBorderCustomStyled'
        },
        bedrooms: {
            error: false,
            message: ''
        },
        bathrooms: {
            error: false,
            message: ''
        },
        accommodates: {
            error: false,
            message: ''
        }
    }

    const [errorMessage, setErrorMessage] = useState(validationBase);

    const changeLocationData = (key: string, value: any) => {
        changePropertyHookData(lead, setLead, key, value);
    }

    const changeFormPage = () => {
        if (validateFormData()) {
            setCurrentFormPage(currentFormPage + 1);
        }
    }

    const changeLeadData = (key: string, value: any) => {

        if ((key === 'bedrooms' || key === 'bathrooms' || key === 'accommodates') && value < 0) {
            value = 0;
        }
        changePropertyHookData(lead, setLead, key, value);
    }

    const validateFormData = (): boolean => {

        setErrorMessage(validationBase);
        let errorFlag = true;
        let validationBaseCopy = validationBase;

        if (lead.property.bedrooms == 0) {
            validationBaseCopy.bedrooms.error = true;
            validationBaseCopy.bedrooms.message = t('propertyFormNotZero');
            errorFlag = false;
        }

        if (lead.property.bathrooms == 0) {
            validationBaseCopy.bathrooms.error = true;
            validationBaseCopy.bathrooms.message = t('propertyFormNotZero');
            errorFlag = false;
        }

        if (lead.property.accommodates == 0) {
            validationBaseCopy.accommodates.error = true;
            validationBaseCopy.accommodates.message = t('propertyFormNotZero');
            errorFlag = false;
        }

        if (errorFlag) {
            setErrorMessage(validationBaseCopy);
        }

        return errorFlag;
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <label htmlFor="react-select-react-select-3-live-region-input"
                           style={{visibility: 'hidden', position: 'absolute'}}>label</label>
                    <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                        apiOptions={{
                            region: 'es'
                        }}
                        selectProps={{
                            defaultInputValue: lead.property.address,
                            required: true,
                            placeholder: t('propertyFormInput'),
                            onChange: (e: any) => changeLocationData('address', e.label),
                            onKeyDown: () => {
                                googleOptions.splice(0, googleOptions.length);
                            },
                            getOptionLabel: (option) => {
                                googleOptions.push(option.label);
                                return option.label;
                            },
                            noOptionsMessage: () => t('propertyFormLookingForALocation'),
                            instanceId: 'react-select-3-live-region',
                            className: `selectCustomStyled ${errorMessage.location.classReference}`,
                            classNames: {
                                control: (props: ControlProps<Option, false, GroupBase<Option>>) => {
                                    return 'selectInputCustomStyled'
                                },
                                menu: (props: MenuProps<Option, false, GroupBase<Option>>) => {
                                    return 'selectMenuCustomStyled';
                                }
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CustomTextField fullWidth
                                     size={'small'}
                                     error={errorMessage.bedrooms.error}
                                     helperText={errorMessage.bedrooms.message}
                                     type={'number'}
                                     required={true}
                                     value={lead.property.bedrooms}
                                     label={t('propertyFormRooms')}
                                     id="propertyDetailRoomForm"
                                     onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('bedrooms', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CustomTextField fullWidth
                                     size={'small'}
                                     error={errorMessage.bathrooms.error}
                                     helperText={errorMessage.bathrooms.message}
                                     type={'number'}
                                     required={true}
                                     value={lead.property.bathrooms}
                                     label={t('propertyFormBathrooms')}
                                     id="propertyDetailBathroomsForm"
                                     onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('bathrooms', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <CustomTextField fullWidth
                                     size={'small'}
                                     error={errorMessage.accommodates.error}
                                     helperText={errorMessage.accommodates.message}
                                     type={'number'}
                                     required={true}
                                     value={lead.property.accommodates}
                                     label={t('propertyFormPax')}
                                     id="propertyDetailBathroomsForm"
                                     onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('accommodates', e.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        disabled={lead.property.address.length <= 3}
                        variant={'outlined'}
                        className={'custom-winahost-button'}
                        onClick={() => changeFormPage()}
                    >
                        {t('propertyFormButtonSend')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default PropertyForm
