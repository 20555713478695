import {Grid, Hidden, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {ES_CONTACT} from "../../../routes/constantsRoutes";
import {LIVINGROOM_IMAGE} from "../../../Shared/Constants/imageConstants";


const ManagementContactHeader = () => {

    const {t} = useTranslation('', {keyPrefix: 'servicePage.managementHeader'});

    const contactPath = ES_CONTACT;

    return (
        <Grid container justifyContent={'center'} mt={9}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={11} sm={9}>
                        <Typography variant="h1" className={'managementHeaderContactTitle'} mb={5} fontWeight={700}
                                    color={'var(--main-winahost-color)'} textAlign={'center'}
                                    dangerouslySetInnerHTML={createMarkup(t('managementHeaderTitle'))}/>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} className={'managementHeaderContactImageTitle'}>
                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                        <Grid container justifyContent={'center'} mt={2}>
                            <Grid item xs={12} className={'contenedor'}>
                                <img src={LIVINGROOM_IMAGE}
                                     alt=""
                                     style={{width: '100%', borderRadius: 30}}
                                />
                                <Hidden smDown>
                                    <Typography variant={'body1'} fontSize={'1.7em'} color={'black'} fontWeight={300} mb={5} className={'texto'}
                                            dangerouslySetInnerHTML={createMarkup(t('managementHeaderBody'))}/>
                                </Hidden>
                            </Grid>
                            <Hidden smUp>
                                <Grid item xs={11} mt={5}>
                                    <Typography variant={'body1'} fontSize={'1.7em'} color={'black'} fontWeight={300} mb={5} className={'texto'}
                                            dangerouslySetInnerHTML={createMarkup(t('managementHeaderBody'))}/>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ManagementContactHeader;
