import {Grid, Typography} from "@mui/material";
import PropertyForm from "./propertyForm";
import UserForm from "./userForm";
import {LeadInterface} from "../../interfaces/leadInterface";
import SimpleSkeleton from "../skeletons/simpleSkeleton";
import CalculateSuccessForm from "./calculateSuccessForm";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import PropertyQuestionsForm from "./propertyQuestionsForm";
import ShowErrorMessageRentalizeForm from "./showErrorMessageRentalizeForm";

interface props {
    lead: LeadInterface,
    setLead: Function,
    currentFormPage: number,
    setCurrentFormPage: Function,
    customFunction: Function,
    rentalizerData: any | null,
    rentalizerErrorMessage: string | null
}

const SmallForm = ({
                       lead,
                       setLead,
                       currentFormPage,
                       setCurrentFormPage,
                       customFunction,
                       rentalizerData,
                       rentalizerErrorMessage
                   }: props) => {

    const {t} = useTranslation('', {keyPrefix: 'mainPage'});

    const changeFormPage = () => {
        setCurrentFormPage(currentFormPage + 1);
    }

    const changeToUserFormPage = () => {
        changeFormPage();
        customFunction();
    }

    const getFormPage = () => {
        switch (currentFormPage) {
            case 1:
                return <PropertyForm lead={lead} setLead={setLead} customFunction={changeFormPage}/>
            case 2:
                return <PropertyQuestionsForm lead={lead} setLead={setLead} customFunction={changeFormPage}/>
            case 3:
                return <UserForm lead={lead} setLead={setLead} customFunction={changeToUserFormPage}/>
            case 4:
                if (rentalizerErrorMessage) {
                    return (
                        <Grid item xs={12} mt={5}>
                            <ShowErrorMessageRentalizeForm/>
                        </Grid>
                    )
                } else if (rentalizerData) {
                    return <CalculateSuccessForm revenueProperty={rentalizerData}/>
                }
                return <SimpleSkeleton/>
        }
    }

    const getTextPage = () => {
        switch (currentFormPage) {
            case 1:
                return (
                    <>
                        <Typography variant="h1" mb={5} fontSize="1.3em" fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup('Nuestra Calculadora te estima al instante 4 indicadores de ingresos y rentabilidad de tu alquiler.')}/>
                        <Typography variant="h2" fontSize={'.8em'} mb={5} fontWeight={200}
                                    dangerouslySetInnerHTML={createMarkup(t('formularySubTitle'))}/>
                    </>
                )
            case 2:
                return <Typography variant={'body1'} fontSize={'1.3em'} p={3} color={'var(--main-winahost-color)'}
                                   fontWeight={400}
                                   dangerouslySetInnerHTML={createMarkup('Necesitamos esta infomación sobre tu propiedad')}/>
            case 3:
                return <Typography variant={'body1'} fontSize={'1.3em'} p={3} color={'var(--main-winahost-color)'}
                                   fontWeight={400}
                                   dangerouslySetInnerHTML={createMarkup('Obtén información más detallada de tu propiedad')}/>
            case 4:
                if (rentalizerErrorMessage) {
                    return null;
                } else if (rentalizerData) {
                    return <Typography variant={'body1'} fontSize={'1.2em'} p={3} color={'var(--main-winahost-color)'}
                                       fontWeight={400}
                                       dangerouslySetInnerHTML={createMarkup('Aquí tienes estimaciones más detalladas de tu ubicación')}/>
                }
        }
    }

    return (
        <>
            {getTextPage()}
            <Grid container justifyContent={'center'}>
                {getFormPage()}
            </Grid>
        </>
    );
}

export default SmallForm;
