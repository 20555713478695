import {Button, Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import LeadBasicFormData from "../forms/leadBasicFormData";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import {useEffect, useState} from "react";
import {validationEmail, validationPhone} from "../../../../Shared/utils/validations";

interface props {
    lead: LeadInterface,
    setLead: Function,
    currentFunction: Function
}

const HeaderMainForm = ({lead, setLead, currentFunction}: props) => {

    const [sendButton, setSendButton] = useState(true);

    const availableSendButton = () => {
        let dataEmail = validationEmail(lead.user.email);
        let dataPhone = validationPhone(lead.user.phone);

        setSendButton((dataEmail || dataPhone));
    }

    useEffect(() => {
        availableSendButton();
    }, [lead])

    return (
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11} className={'mainPageTitle'}>

            <Typography variant="h1" dangerouslySetInnerHTML={createMarkup('Expertos en Alquiler Turístico y Temporal')}/>
            <Typography variant="h2" fontSize={'1.2em'} fontWeight={600} mt={5}
                        dangerouslySetInnerHTML={createMarkup('Rellena los datos, ¡Hoy mismo te contactaremos!')}/>

            <Grid item xs={12} mt={3} mb={5}>
                <LeadBasicFormData lead={lead} setLead={setLead}/>
            </Grid>
            <Button fullWidth className={'custom-winahost-button'} disabled={sendButton} onClick={() => currentFunction()}>
                                    <span style={{fontWeight: 'bold'}}>
                                        {'contacta ahora'}
                                    </span>
            </Button>
            <Grid item xs={12} mt={5}>
                <Typography variant={'body1'} mb={5} color={'black'} fontSize={'.8em'}
                            dangerouslySetInnerHTML={createMarkup('Al enviarlo, acepto que la Política de privacidad, cookies y Política de tratamiento de datos de Winahost se apliquen a las comunicaciones de Winahost')}>
                </Typography>
            </Grid>

        </Grid>
    );
}

export default HeaderMainForm;
