
/**
 * LOGOS
 * */

export const AIRBNB_WHITE = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/airbnb_white.svg';
export const AIRBNB_WINAHOST = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/airbnb_winahost.svg';
export const BOLSAMANIA_VECTOR_LOGO = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/bolsamania-vector-logo.png';
export const BOOKING_WHITE = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/booking_white.svg';
export const BOOKING_WINAHOST = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/booking_winahost.svg';
export const EL_REFERENTE_LOGO = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/el_referente_logo.jpg';
export const EXPANSION = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/expansion.jpg';
export const FACEBOOK_ICON = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/facebook_icon.svg';
export const FORBES_LOGO = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/forbes_logo.png';
export const HOLIDU_WHITE = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/holidu_white.svg';
export const HOLIDU_WINAHOST = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/holidu_winahost.svg';
export const INSTAGRAM_ICON = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/instagram_icon.svg';
export const LA_VANGUARDIA_VECTOR_LOGO = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/la-vanguardia-vector-logo.png';
export const LINKEDIN_ICON = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/linkedin_icon.svg';
export const LOCASUN_WHITE = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/locasun_white.svg';
export const LOCASUN_WINAHOST = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/locasun_winahost.svg';
export const PC_CONFIGURATION = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/pc_configuration.svg';
export const TECHNOLOGY_EARNING = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/technology_earning.svg';
export const TRIPADVISOR_WHITE = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/tripadvisor_white.svg';
export const TRIPADVISOR_WINAHOST = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/tripadvisor_winahost.svg';
export const USA_NEWS_1CFD7A3AF221D19F10D25FAD0A75A724 = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/usa_news_1cfd7a3af221d19f10d25fad0a75a724.jpg';
export const VRBO_WHITE = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/vrbo_white.svg';
export const VRBO_WINAHOST = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/vrbo_winahost.svg';
export const WEBCAPITALRIESGO_300X150 = 'https://winahost.s3.eu-west-1.amazonaws.com/images/logos/webcapitalriesgo-300x150.webp';
