import {Grid, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import {ChangeEvent, useState} from "react";
import {LeadInterface} from "../../interfaces/leadInterface";
import * as React from "react";

interface props {
    lead: LeadInterface,
    setLead: Function,
    customFunction: Function,
}

const PropertyQuestionsForm = (
    {
        lead,
        setLead,
        customFunction
    }: props) => {



    const validationBase = {
        location: {
            error: false,
            classReference: 'selectBorderCustomStyled'
        },
        bedrooms: {
            error: false,
            message: ''
        },
        bathrooms: {
            error: false,
            message: ''
        },
        accommodates: {
            error: false,
            message: ''
        }
    }

    const [errorMessage, setErrorMessage] = useState(validationBase);

    const callCustomFunction = () => {
        if (validateFormData()) {
            customFunction();
        }
    }

    const changeLeadData = (key: string, value: any) => {

        if ((key === 'bedrooms' || key === 'bathrooms' || key === 'accommodates') && value < 0) {
            value = 0;
        }
        setLead({
            ...lead,
            property: {
                ...lead.property,
                [key]: value
            }
        });
    }

    const validateFormData = (): boolean => {

        setErrorMessage(validationBase);
        let errorFlag = true;
        let validationBaseCopy = validationBase;

        if (lead.property.bedrooms == 0) {
            validationBaseCopy.bedrooms.error = true;
            validationBaseCopy.bedrooms.message = 'No puede ser 0';
            errorFlag = false;
        }

        if (lead.property.bathrooms == 0) {
            validationBaseCopy.bathrooms.error = true;
            validationBaseCopy.bathrooms.message = 'No puede ser 0';
            errorFlag = false;
        }

        if (lead.property.accommodates == 0) {
            validationBaseCopy.accommodates.error = true;
            validationBaseCopy.accommodates.message = 'No puede ser 0';
            errorFlag = false;
        }

        if (errorFlag) {
            setErrorMessage(validationBaseCopy);
        }

        return errorFlag;
    }

    return (
        <>
            <Grid container p={2} spacing={3}>

                <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">¿Eres el dueño de esta propiedad?</FormLabel>
                        <RadioGroup aria-label="owner" name="owner" value={lead.property.owner}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('owner', e.target.value)}>
                            <FormControlLabel value="yes" control={<Radio/>} label="Sí"/>
                            <FormControlLabel value="no" control={<Radio/>} label="No"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">¿Cuál es la disponibilidad de tu propiedad para alquilar?</FormLabel>
                        <RadioGroup aria-label="gender" name="availability" value={lead.property.availability} onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('availability', e.target.value)}>
                            <FormControlLabel value="fulltime" control={<Radio/>}
                                              label="Tiempo completo (9-12 meses por año)"/>
                            <FormControlLabel value="halftime" control={<Radio/>}
                                              label="Tiempo parcial (menos de 9 meses)"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>


                <Grid item xs={12} md={6} mt={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">¿Estas pensando en contratar una empresa de gestión para alquilar tu vivienda?</FormLabel>
                        <RadioGroup aria-label="hire" name="hire" value={lead.property.hire}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('hire', e.target.value)}>
                            <FormControlLabel value="yes" control={<Radio/>} label="Sí"/>
                            <FormControlLabel value="no" control={<Radio/>} label="No"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} mt={3}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">¿Tienes tramitada la licencia necesaria para alquilar en tu ciudad?</FormLabel>
                        <RadioGroup aria-label="licence" name="licence" value={lead.property.licence}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('licence', e.target.value)}>
                            <FormControlLabel value="yes" control={<Radio/>} label="Sí"/>
                            <FormControlLabel value="no" control={<Radio/>} label="No, pero planeo tramitarla en breve"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Button
                        disabled={lead.property.address.length <= 3}
                        variant={'outlined'}
                        style={{
                            width: '100%',
                            padding: '20px 0',
                            backgroundColor: 'var(--main-winahost-color)',
                            color: 'white',
                            borderRadius: 50
                        }}
                        onClick={() => callCustomFunction()}
                    >
                        {'Sólo te queda un paso más'}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default PropertyQuestionsForm
