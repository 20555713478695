import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import {t} from "i18next";
import BaseLayout from "../../Shared/layouts/baseLayout";

const NotFound = () => {
    return (
        <BaseLayout>
            <Grid container justifyContent={'center'} mt={5} mb={5}>
                <Grid item xs={12} sm={10} md={9} lg={8} xl={8}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} textAlign={'center'}>
                            <Typography fontSize={'18rem'} color={'var(--main-winahost-color)'}
                                        fontWeight={900}
                                        dangerouslySetInnerHTML={createMarkup(t('404'))}/>
                            <Typography variant={'body1'} fontSize={'6rem'} color={'black'}
                                        dangerouslySetInnerHTML={createMarkup(t('Página no encontrada'))}/>
                            <Typography variant={'body1'} fontSize={'1.5em'} color={'black'}
                                        dangerouslySetInnerHTML={createMarkup(t('Ups, algo ha ido mal...'))}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>
    )
}

export default NotFound;
