import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import QuickContactEmailForm from "../forms/quickContactEmailForm";
import {useState} from "react";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import {leadFormQuickContactBase} from "../../../../Shared/baseData/leadBaseData";
import QuickContactUserDataForm from "../forms/quickContactUserDataForm";
import {Grid, StepLabel} from "@mui/material";
import {
    gtagLandingQuickContactConversion,
    metaLeadTrack
} from "../../../../hooks/socialMeditionTags";
import {formatLeadMergeData} from "../../../../hooks/leadsHook";
import {upsertHubspotContact} from "../../../../Shared/hooks/upsertHubspotContact";


interface props {
    activeStep: number,
    setActiveStep: Function
}



const steps = ['', ''];

export default function QuickContactStepper({activeStep, setActiveStep}: props) {

    const [lead, setLead] = useState<LeadInterface>(leadFormQuickContactBase);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleSendLeadToHubspot = () => {

        metaLeadTrack();
        gtagLandingQuickContactConversion();

        const leadMergeData = formatLeadMergeData(lead);
        upsertHubspotContact(leadMergeData);
    }

    const handleNextAndSendLead = () => {
        handleNext();
        handleSendLeadToHubspot();
    }

    const getFormStepper = () => {
        switch (activeStep) {
            case 0:
                return <QuickContactEmailForm lead={lead} setLead={setLead} currentFunction={() => handleNext()}/>;
            case 1:
                return <QuickContactUserDataForm lead={lead} setLead={setLead}
                                                 currentFunction={() => handleNextAndSendLead()}/>;
            default:
                return 'not form available';
        }
    }

    return (
        <Box sx={{width: '100%'}}>
            <Grid container justifyContent={'center'}>
                <Grid item xs={12} sm={9} md={7}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => (
                            <Step key={index}>
                                <StepLabel/>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
            </Grid>
            <div>
                <React.Fragment>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={12} mt={5}>
                            {getFormStepper()}
                        </Grid>
                    </Grid>
                </React.Fragment>
            </div>
        </Box>
    );
}
