import React from 'react';
import { Box, Grid, Paper, Typography } from "@mui/material";
import { createMarkup } from "../../../Shared/utils/textUtilsResources";
import { FaCogs, FaChartLine, FaHome, FaDatabase } from 'react-icons/fa';
import { motion } from 'framer-motion';

const LANDING_IMAGE = 'https://s3.eu-west-1.amazonaws.com/winahost.com/images/landingAt2.jpg';

const Property360SpaceLanding = () => {
    const squares = [
        {
            title: 'Multiplataforma',
            description: createMarkup('Conectividad con principales plataformas.'),
            icon: <FaHome size={20} color="#367E7F" />,
        },
        {
            title: 'Automatic Pricing',
            description: createMarkup('Algoritmos que ajustan precios en tiempo real.'),
            icon: <FaChartLine size={20} color="#367E7F" />,
        },
        {
            title: 'Automatización',
            description: createMarkup('Check-ins y check-outs automatizados.'),
            icon: <FaCogs size={20} color="#367E7F" />,
        },
        {
            title: 'BIG DATA & IA',
            description: createMarkup('Tecnología avanzada para máximo rendimiento.'),
            icon: <FaDatabase size={20} color="#367E7F" />,
        },
    ];

    return (
        <Box
            sx={{
                backgroundColor: '#F5F5F5',
                py: 4,
                px: 2,
                maxWidth: '1200px',
                margin: '0 auto', 
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
                borderRadius: '16px',
            }}
        >
            <Typography
                variant="h4"
                mb={4}
                fontWeight={700}
                color="#367E7F"
                textAlign="center"
                sx={{
                    fontSize: { xs: '1.5rem', md: '2rem' }, 
                }}
            >
                ¿Qué te ofrece Winahost?
            </Typography>

            <Grid container spacing={4} alignItems="center">
                <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    direction="column"
                    spacing={3}
                    sx={{ order: { xs: 2, md: 1 } }} 
                >
                    {squares.map((square, index) => (
                        <Grid item key={index}>
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    x: index % 2 === 0 ? -30 : 30,
                                    scale: 0.8,
                                }}
                                animate={{
                                    opacity: 1,
                                    x: 0,
                                    scale: 1, 
                                }}
                                transition={{
                                    delay: index * 0.2,
                                    duration: 0.8,
                                    type: 'spring',
                                    stiffness: 100,
                                    damping: 15,
                                }}
                            >
                                <Paper
                                    elevation={3}
                                    sx={{
                                        borderRadius: '12px',
                                        padding: '20px',
                                        backgroundColor: '#FFFFFF',
                                        boxShadow: '0 8px 15px rgba(54, 126, 127, 0.15)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={700}
                                            color="#000000"
                                            sx={{ fontSize: '1rem' }}
                                        >
                                            {square.title}
                                        </Typography>
                                        {square.icon}
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        color="#444"
                                        sx={{
                                            fontSize: '0.875rem',
                                            lineHeight: 1.5,
                                        }}
                                        dangerouslySetInnerHTML={square.description}
                                    />
                                </Paper>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        order: { xs: 1, md: 2 },
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <motion.img
                        src={LANDING_IMAGE}
                        alt="Landing"
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.8 }}
                        style={{
                            width: '100%',
                            maxWidth: '400px',
                            height: 'auto',
                            borderRadius: '15px',
                            objectFit: 'cover',
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Property360SpaceLanding;