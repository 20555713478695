import {Grid, Hidden, Typography} from "@mui/material";
import {useState} from "react";
import PropertyForm from "./leadForm/propertyForm";
import PropertyUserForm from "./leadForm/propertyUserForm";
import SuccessForm from "./leadForm/successForm";
import '../../../Styles/mainPage.css';
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {getAirDnaRentalizeSummaryData} from "../hooks/getAirDnaRentalizeSummaryData";
import {upsertHubspotContact} from "../../../Shared/hooks/upsertHubspotContact";
import {useTranslation} from "react-i18next";
import {LeadInterface} from "../../../interfaces/leadInterface";
import {leadFormMainBase} from "../../../Shared/baseData/leadBaseData";
import {formatLeadMergeData, formatRentalizeWithLeadMergeData} from "../../../hooks/leadsHook";
import ShowErrorMessageRentalizeForm from "../../../components/forms/showErrorMessageRentalizeForm";
import {gtagLandingMainPageConversion, metaLeadTrack} from "../../../hooks/socialMeditionTags";
import {TECHNOLOGYPHOTO} from "../../../Shared/Constants/imageConstants";


const CalculatorLayoutBase = () => {

    const {t} = useTranslation('', {keyPrefix: 'mainPage'});

    const [currentFormPage, setCurrentFormPage] = useState(1)

    const [lead, setLead] = useState<LeadInterface>(leadFormMainBase);

    const [revenueProperty, setRevenueProperty] = useState(null);

    const [rentalizerErrorMessage, setRentalizerErrorMessage] = useState<string | null>(null);

    const getRevenueData = () => {
        const leadMergeData = formatLeadMergeData(lead);
        getAirDnaRentalizeSummaryData(leadMergeData)
            .then((response) => {
                if (response.error) {
                    setRentalizerErrorMessage(response.error.message);
                    const leadMergeData = formatLeadMergeData(lead);
                    sendHubspotInformation(leadMergeData);
                } else {
                    setRevenueProperty(response);
                    const leadMergeData = formatRentalizeWithLeadMergeData(lead, response);
                    sendHubspotInformation(leadMergeData);
                }
            })
            .catch((error) => {
                console.error("Error fetching revenue data:", error);
            });
    }

    const sendHubspotInformation = (leadMergeData: Object) => {

        metaLeadTrack();
        gtagLandingMainPageConversion();

        upsertHubspotContact(leadMergeData);
    }

    const getFormPage = () => {
        switch (currentFormPage) {
            case 2:
                return <PropertyUserForm
                    lead={lead}
                    setLead={setLead}
                    currentFormPage={currentFormPage}
                    setCurrentFormPage={setCurrentFormPage}
                    getRevenueData={getRevenueData}
                />
            case 3:
                if (rentalizerErrorMessage) {
                    return <ShowErrorMessageRentalizeForm/>
                } else {
                    return <SuccessForm revenueProperty={revenueProperty}/>
                }
            case 1:
            default:
                return <PropertyForm
                    lead={lead}
                    setLead={setLead}
                    currentFormPage={currentFormPage}
                    setCurrentFormPage={setCurrentFormPage}
                />;
        }
    }

    let showFormularySubTitle = null;
    if (currentFormPage === 1) {
        showFormularySubTitle = (
            <>
                <Typography variant="h2" fontSize={'.8em'} mb={5} fontWeight={200}
                            dangerouslySetInnerHTML={createMarkup(t('formularySubTitle'))}/>
            </>
        )
    }

    let showFormularyTitles;
    if (!rentalizerErrorMessage) {
        showFormularyTitles = (
            <>
                <Typography variant="h1" mb={2} dangerouslySetInnerHTML={createMarkup(t('formularyTitle'))}/>
                <Typography variant="body1" fontSize={'.8em'} mb={2} fontWeight={600}
                            dangerouslySetInnerHTML={createMarkup(t('formularySecondSubTitle'))}/>
            </>
        );
    }

    return (
        <Grid container justifyContent={'center'} className={'calculatorDegree'} mt={3} id={'mainForm'}>
            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                <Grid container justifyContent={'center'} spacing={5} alignItems={'center'}>
                    <Grid item xs={11} sm={11} md={6} className={'formularyPageTitle'}>
                        {showFormularyTitles}
                        {showFormularySubTitle}
                        {getFormPage()}
                    </Grid>
                    <Hidden mdDown>
                        <Grid item sm={11} md={6}>
                            <Grid container justifyContent={'center'}>
                                <Grid item xs={11} sm={12} md={12} lg={12} xl={10} textAlign={'right'}>
                                    <img src={TECHNOLOGYPHOTO}
                                         alt={"coast_town"}
                                         style={{
                                             width: '92%'
                                         }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CalculatorLayoutBase;
