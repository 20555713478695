import {Grid, Typography} from "@mui/material";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {t} from "i18next";
import {ControlProps, GroupBase, MenuProps} from "react-select";
import {Option} from "react-google-places-autocomplete/build/types";
import * as React from "react";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import {changePropertyHookData} from "../../../../hooks/leadsHook";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import { validationFormPage } from "../../landingForm";

interface props {
    lead: LeadInterface,
    setLead: Function,
    validationFormPage: validationFormPage,
    setValidationFormPage: Function
}

const LocationLandingForm = ({lead, setLead, validationFormPage, setValidationFormPage}: props) => {

    const changeLocationData = (key: string, value: any) => {
        changePropertyHookData(lead, setLead, key, value);
        setValidationFormPage({...validationFormPage, location: true})
    }

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12}>

                <Typography variant={'body1'} fontWeight={600} mb={3} mt={3} className={'landingFormTitles'}
                            dangerouslySetInnerHTML={createMarkup('Infórmate con nuestros expertos')}/>

                <Grid container justifyContent={'center'}>
                    <Grid item xs={12}>
                        <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                            apiOptions={{
                                region: 'es'
                            }}
                            selectProps={{
                                defaultInputValue: lead.property.address,
                                placeholder: t('mainPage.leadForm.propertyForm.propertyFormInput'),
                                onChange: (e: any) => changeLocationData('address', e.label),
                                instanceId: 'react-select-3-live-region',
                                className: 'selectCustomStyled',
                                classNames: {
                                    control: (props: ControlProps<Option, false, GroupBase<Option>>) => {
                                        return 'selectInputCustomStyled'
                                    },
                                    menu: (props: MenuProps<Option, false, GroupBase<Option>>) => {
                                        return 'selectMenuCustomStyled';
                                    }
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LocationLandingForm;
