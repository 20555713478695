import {Button, Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import LeadBasicFormData from "../forms/leadBasicFormData";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {validationEmail, validationPhone} from "../../../../Shared/utils/validations";

interface props {
    lead: LeadInterface,
    setLead: Function,
    currentFunction: Function
}

const BeginSectionForm = ({lead, setLead, currentFunction}: props) => {

    const {t} = useTranslation('', {keyPrefix: 'servicePage.beginSection'});

    const [sendButton, setSendButton] = useState(true);

    const availableSendButton = () => {
        let dataEmail = validationEmail(lead.user.email);
        let dataPhone = validationPhone(lead.user.phone);

        setSendButton((dataEmail || dataPhone));
    }

    useEffect(() => {
        availableSendButton();
    }, [lead])

    return (
        <>
            <Typography variant="h3" component="h3" fontSize={'1.7em'} mb={4} fontWeight={700} color={'black'}
                        dangerouslySetInnerHTML={createMarkup(t('beginSectionTitle'))}>
            </Typography>
            <Typography variant={'body1'} mb={5} color={'black'}
                        dangerouslySetInnerHTML={createMarkup(t('beginSectionBody'))}>
            </Typography>

            <Grid item xs={12} mt={5} mb={5}>
                <LeadBasicFormData lead={lead} setLead={setLead}/>
            </Grid>

            <Button className={'custom-winahost-button'} disabled={sendButton} onClick={() => currentFunction()}>
                                    <span style={{fontWeight: 'bold'}}>
                                        {'Empezar'}
                                    </span>
            </Button>
            <Grid item xs={12} mt={5}>
                <Typography variant={'body1'} mb={5} color={'black'} fontSize={'.8em'}
                            dangerouslySetInnerHTML={createMarkup('Al enviarlo, acepto que la Política de privacidad, cookies y Política de tratamiento de datos de Winahost se apliquen a las comunicaciones de Winahost')}>
                </Typography>
            </Grid>
        </>
    );
}

export default BeginSectionForm;
