import {Button, Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import * as React from "react";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import { validationFormPage } from "../../landingForm";
import {useState} from "react";
import {changePropertyHookData} from "../../../../hooks/leadsHook";

interface props {
    lead: LeadInterface,
    setLead: Function,
    validationFormPage: validationFormPage,
    setValidationFormPage: Function,
    handleChangePageAdd: Function
}


const TypeHireLandingForm = ({lead, setLead, validationFormPage, setValidationFormPage, handleChangePageAdd}: props) => {

    const [buttonSelected, setButtonSelected] = useState<string>('');

    const handleSelectedButton = (value: string) => {
        setButtonSelected(value);
        changePropertyData('type_hire', value);
        setValidationFormPage({...validationFormPage, hire: true})
        handleChangePageAdd();
    }

    const changePropertyData = (key: string, value: any) => {
        changePropertyHookData(lead, setLead, key, value);
    }

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12}>

                <Typography variant={'body1'} fontWeight={600} mb={3} mt={3} className={'landingFormTitles'}
                            dangerouslySetInnerHTML={createMarkup('¿Quieres información sobre nuestros servicios de alquiler?')}/>

                <Grid container justifyContent={'center'} spacing={3}>
                    <Grid item xs={12} sm={11} md={6}>
                        <Button
                            fullWidth
                            variant={'outlined'}
                            style={(buttonSelected === 'tourism' || lead.property.type_hire === 'tourism') ? styles.activeButton : styles.disabledButton}
                            onClick={() => handleSelectedButton('tourism')}
                        >
                            <Typography variant={'body1'} fontWeight={600} fontSize={'.8em'} style={(buttonSelected === 'tourism' || lead.property.type_hire === 'tourism') ? styles.activeText : styles.disabledText}
                                        dangerouslySetInnerHTML={createMarkup('Si, quiero mi vivienda en alquiler turístico')}/>
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={11} md={6}>
                        <Button
                            fullWidth
                            variant={'outlined'}
                            style={(buttonSelected === 'temporal' || lead.property.type_hire === 'temporal') ? styles.activeButton : styles.disabledButton}
                            onClick={() => handleSelectedButton('temporal')}
                        >
                            <Typography variant={'body1'} fontWeight={600} fontSize={'.8em'}
                                        style={(buttonSelected === 'temporal' || lead.property.type_hire === 'temporal') ? styles.activeText : styles.disabledText}
                                        dangerouslySetInnerHTML={createMarkup('Sí, quiero mi vivienda en alquiler temporal')}/>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const styles = {
    activeButton: {
        backgroundColor: 'var(--main-winahost-color)',
        padding: '20px 0',
        color: '#fff',
        borderRadius: 15
    },
    disabledButton: {
        padding: '20px 0',
        borderRadius: 15
    },
    activeText: {
        color: 'white'
    },
    disabledText: {
        color: 'var(--main-winahost-color)'
    }
}

export default TypeHireLandingForm;
