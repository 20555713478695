import React from 'react';
import {Grid} from "@mui/material";

import './../../Styles/authGlobals.css'
import './../../Styles/drawerCss.css';
import './../../Styles/footerCss.css';


interface props {
    children: JSX.Element | JSX.Element[]
}

const BaseAuthLayout = ({children}: props) => {

    return (
        <Grid container>
            <Grid container justifyContent="center" className={'authBasePageBackground'}>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BaseAuthLayout;
