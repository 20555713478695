import {Grid, Hidden, Paper, Typography} from "@mui/material";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {formatNumber} from "../../../Shared/utils/formatNumbers";
import {grey} from "@mui/material/colors";
import {WINAHOST_GRAPHYCS} from "../../../Shared/Constants/imageConstants";

interface props {
    rentalizerData: any | null
}

const RevenueParameters = ({rentalizerData}: props) => {

    let rentalizerDataContent = imageRentalizerData();

    if (rentalizerData !== null) {
        rentalizerDataContent = showRentalizerParameters(rentalizerData);
    }

    return rentalizerDataContent;
}


const showRentalizerParameters = (rentalizerData: any) => {
    return (
        <Grid container justifyContent={'center'}>
            <Typography variant={'body1'} fontSize={'1.2em'} mt={3} mb={3} color={'var(--main-winahost-color)'}
                        fontWeight={400}
                        dangerouslySetInnerHTML={createMarkup('Estos son tus indicadores')}/>

            <Grid container justifyContent={'center'} spacing={3}>
                {informationSquare('Ingresos año estimados', formatNumber(rentalizerData.property_stats.revenue.ltm.toFixed()) + ' ' + rentalizerData.property_details.currency_symbol,
                    <CheckCircleOutlineIcon sx={{color: grey[50]}} fontSize="large"/>, 'rgb(0,128,128)')}

                {informationSquare('Tarifa media diaria', formatNumber(rentalizerData.property_stats.adr.ltm.toFixed()) + ' ' + rentalizerData.property_details.currency_symbol,
                    <CalendarMonthIcon sx={{color: grey[50]}} fontSize="large"/>, 'rgb(0,128,128)')}

                {informationSquare('Ocupación promedio', (rentalizerData.property_stats.occupancy.ltm * 100).toFixed() + ' %',
                    <AccountCircleIcon sx={{color: grey[50]}} fontSize="large"/>, 'rgb(0,128,128)')}

                {informationSquare('Rentabilidad estimada', formatNumber((parseFloat(rentalizerData.property_stats.revenue.ltm) * 0.77).toFixed()) + ' ' + rentalizerData.property_details.currency_symbol,
                    <AccountBalanceIcon sx={{color: grey[50]}} fontSize="large"/>, 'rgb(0,128,128)')}
            </Grid>
        </Grid>
    );
}

const informationSquare = (title: string, content: string, icon: JSX.Element, color: string) => {
    return (
        <Grid item xs={11} sm={11} md={6}>
            <Paper
                style={{
                    padding: '30px 0 30px 0',
                    borderRadius: 10,
                    backgroundColor: `${color}`,
                }}
                elevation={3}
            >
                <Grid container justifyContent={'center'}>
                    <Grid item xs={9}>
                        <Grid container justifyContent={'center'} spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="body1" color={'white'}
                                            dangerouslySetInnerHTML={createMarkup(title)}/>
                            </Grid>
                            <Grid item xs={3} style={{margin: 'auto auto', textAlign: 'center'}}>
                                {icon}
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="body1" fontSize={'2em'} fontWeight={600} color={'white'}
                                            dangerouslySetInnerHTML={createMarkup(content)}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}

const imageRentalizerData = () => {
    return (
        <Hidden mdDown>
            <Grid item sm={11}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={11} sm={12} md={12} lg={12} xl={10}>
                        <img src={WINAHOST_GRAPHYCS}
                             alt={"coast_town"}
                             style={{
                                 width: '120%',
                                 marginTop: '100px'
                             }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Hidden>
    );
}

export default RevenueParameters;
