import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import * as React from "react";
import PlanPricePageSection from "./planPricePageSection";
import {CALM_MAN} from "../../../Shared/Constants/imageConstants";

const PremiumSection = () => {
    return (
        <PlanPricePageSection>
            <Grid container justifyContent={'center'} mt={5} mb={5}>
                <Grid item xs={11} sm={10} md={8} lg={8}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={11} sm={11} md={8} lg={6}>

                            <Typography variant={'body1'} fontSize={'1,7em'} fontWeight={600}
                                        dangerouslySetInnerHTML={createMarkup('Plan Premium')}/>

                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('Todo la gestión en nuestras manos')}/>

                            <Grid item xs={12} mt={5} p={1}>
                                <ul>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Gestión Completa de Reservas')}/>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Check in/ Check Out')}/>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Limpieza y Lavandería')}/>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Mantenimiento')}/>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Anuncio en las principales Plataformas de Reservas')}/>
                                    </li>
                                    {/*
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Fotografía profesional especializada')}/>
                                    </li>
                                    */}
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Optimización de precios y plataforma')}/>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Atención 24/7 los 365 días del año')}/>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Comunicación Multiidioma')}/>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Servicios exclusivos huéspedes')}/>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Asesor Winahost')}/>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Posicionamiento Premium')}/>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Plataforma APP Propietarios')}/>
                                    </li>
                                    <li>
                                        <Typography variant={'body1'} mt={2}
                                                    dangerouslySetInnerHTML={createMarkup('Big Data')}/>
                                    </li>
                                </ul>
                            </Grid>

                        </Grid>
                        <Grid item xs={11} sm={11} md={4} lg={6}>
                            <img src={CALM_MAN} alt={"calm_man"} width={'100%'} style={{borderRadius: 30}}/>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent={'center'} mt={8}>
                        <Grid item xs={11} sm={11} md={10} lg={8}>

                            <Typography variant={'body1'} fontSize={'1.7em'} color={'black'}
                                        fontWeight={600} mb={5}
                                        dangerouslySetInnerHTML={createMarkup('¿És el plan Premium la mejor opción para mi?')}/>

                            <Typography variant={'body1'}
                                        dangerouslySetInnerHTML={createMarkup('Si quieres obtener el máximo rendimiento de tu propiedad y despreocuparte de toda la gestión de tu propiedad, has encontrado el mejor plan para ti. Nos encargamos de todo.')}/>

                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </PlanPricePageSection>
    );
}

export default PremiumSection;
