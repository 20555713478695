import {Button, Grid, Hidden, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import '../../../Styles/mainPage.css';
import {useTranslation} from "react-i18next";
import {DISENO_INTERIOR_CASA_CONTEMPORANEA} from "../../../Shared/Constants/imageConstants";



const HeaderMainSection = () => {

    const {t} = useTranslation('', {keyPrefix: 'mainPage'});

    const redirectToForm = () => {
        // @ts-ignore
        const scrollDiv = document.getElementById("mainForm").offsetTop;
        window.scrollTo({ top: scrollDiv - 45, behavior: 'smooth'});
    }

    return (
        <Grid container justifyContent={'center'} mt={5} mb={11}>
            <Grid item xs={12} sm={11} md={11} lg={10} xl={9}>
                <Grid container justifyContent={'center'} spacing={4}>
                    <Grid item xs={11} sm={11} md={6} lg={7} xl={6} className={'mainPageTitle'}>
                        <Grid container justifyContent={'center'} mt={5} alignItems={'center'}>
                            <Grid item xs={11} sm={11} md={11} lg={11} xl={11} className={'mainPageTitle'}>

                                <Typography variant="h1" dangerouslySetInnerHTML={createMarkup(t('mainTitle'))}/>
                                <Typography variant="h2" fontSize={'1.2em'} fontWeight={200} mt={3}
                                            dangerouslySetInnerHTML={createMarkup(t('mainSubTitle'))}/>

                                <Hidden mdUp>
                                   <Grid container justifyContent={'center'}>
                                       <Grid item xs={12} textAlign={'center'} mt={3} mb={-3}>
                                           <Button  key={'main_page'} onClick={() => redirectToForm()}
                                                    className={'custom-winahost-button'}
                                           >
                                        <span>
                                            {'Obtén una estimación gratis'}
                                        </span>
                                           </Button>
                                       </Grid>
                                   </Grid>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={11} sm={11} md={6} lg={5} xl={6} mt={5} textAlign={'center'}>
                        <img src={DISENO_INTERIOR_CASA_CONTEMPORANEA}
                             alt={"diseno-interior-casa-contemporanea"}
                             className={'imagePageTitle'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default HeaderMainSection;
