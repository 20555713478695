import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";


interface props {
    revenueProperty: any | null,
}

const SuccessForm = ({revenueProperty}: props) => {

    const {t} = useTranslation('', {keyPrefix: 'mainPage.leadForm.successForm'});

    if (!revenueProperty) return null;
    return (
        <Grid container justifyContent={'center'}>
            <Grid container justifyContent={'center'} p={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" fontSize={'1.7em'} mb={1} fontWeight={400}
                                dangerouslySetInnerHTML={createMarkup(t('successFormTitle'))} textAlign={'center'}/>
                    <Typography variant="body1" fontSize={'2.5em'} mb={1} fontWeight={700} textAlign={'center'}
                                style={{color: 'var(--main-winahost-color)'}}
                                dangerouslySetInnerHTML={createMarkup(revenueProperty.property_stats.revenue.ltm + '€*')}/>
                    <Typography variant="body1" fontSize={14} mb={5} style={{color: 'gray'}}
                                dangerouslySetInnerHTML={createMarkup(t('successFromWarningText'))}/>
                    <Typography variant="body1" fontSize={'1.3em'}
                                dangerouslySetInnerHTML={createMarkup(t('successFormBody'))}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SuccessForm;
