import {Grid, Button} from "@mui/material";
import {ChangeEvent, useEffect, useState} from "react";
import CustomTextField from "../../../../components/customComponents/customTextField";
import {useTranslation} from "react-i18next";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import {changeLeadHookData} from "../../../../hooks/leadsHook";
import {validationEmail, validationPhone, validationTextNotEmpty} from "../../../../Shared/utils/validations";


interface props {
    lead: LeadInterface,
    setLead: Function,
    currentFormPage: number,
    setCurrentFormPage: Function,
    getRevenueData: Function,
}

const PropertyUserForm = (
    {
        lead,
        setLead,
        currentFormPage,
        setCurrentFormPage,
        getRevenueData,
    }: props) => {

    const {t} = useTranslation('', {keyPrefix: 'mainPage.leadForm.propertyUserDetails'});

    const [notValidated, setNotValidated] = useState(true);

    const [showMessage, setShowMessage] = useState({
        name: {
            error: false,
            message: 'Este campo es obligatorio'
        },
        email: {
            error: false,
            message: 'Debe de ser correo electrónico válido'
        },
        phone: {
            error: false,
            message: 'Debe de ser un teléfono válido'
        }
    });

    const changeLeadData = (key: string, value: any) => {

        if (key === 'name') validationNameForm(value);
        if (key === 'email') validationEmailForm(value);
        if (key === 'phone') validationPhoneForm(value);


        changeLeadHookData(lead, setLead, key, value);
    }

    const changeFormPage = (pageValue: number) => {
        setCurrentFormPage(pageValue);
    }

    const validationNameForm = (value: string) => {
        const isValid = validationTextNotEmpty(value);
        setShowMessage(prevState => ({...prevState, name: {...prevState.name, error: isValid}}));
    }

    const validationEmailForm = (value: string) => {
        const isValid = validationEmail(value);
        setShowMessage(prevState => ({...prevState, email: {...prevState.email, error: isValid}}));
    }

    const validationPhoneForm = (value: string) => {
        const isValid = validationPhone(value);
        setShowMessage(prevState => ({...prevState, phone: {...prevState.phone, error: isValid}}));
    }

    const validateUserDataForm = () => {
        if (lead.user.name !== '' && lead.user.email !== '' && lead.user.phone !== '' &&
            !showMessage.name.error && !showMessage.email.error && !showMessage.phone.error) {
            setNotValidated(false);
        } else {
            setNotValidated(true);
        }
    }

    useEffect(() => {
        validateUserDataForm();
    }, [lead.user]);

    return (
        <>
            <Grid container justifyContent={'center'}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12}>
                        <Grid container justifyContent={'center'} spacing={3}>
                            <Grid item xs={12}>
                                <CustomTextField fullWidth
                                                 required={true}
                                                 value={lead.user.name}
                                                 label={t('propertyUserDetailsFullName')}
                                                 id="userFormNameDetail" error={showMessage.name.error}
                                                 helperText={showMessage.name.error ? showMessage.name.message : ''}

                                                 onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('name', e.target.value)}/>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomTextField fullWidth
                                                 required={true}
                                                 type={'email'}
                                                 value={lead.user.email}
                                                 label={t('propertyUserDetailsEmail')}
                                                 id="userFormEmailDetail" error={showMessage.email.error}
                                                 helperText={showMessage.email.error ? showMessage.email.message : ''}

                                                 onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('email', e.target.value)}/>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomTextField fullWidth
                                                 required={true}
                                                 value={lead.user.phone}
                                                 label={t('propertyUserDetailsPhone')}
                                                 id="userFormPhoneDetail" error={showMessage.phone.error}
                                                 helperText={showMessage.phone.error ? showMessage.phone.message : ''}
                                                 onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('phone', e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    disabled={notValidated}
                                    fullWidth
                                    variant={'outlined'}
                                    className={'custom-winahost-button'}
                                    onClick={() => {
                                        getRevenueData();
                                        changeFormPage(currentFormPage + 1);
                                    }}
                                >
                                    {t('propertyUserDetailsButtonSend')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    );
}

export default PropertyUserForm
