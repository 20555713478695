import Grid from "@mui/material/Grid";
import {Banner} from "../banner/banner";
import {
    AIRBNB_WHITE, AIRBNB_WINAHOST, BOOKING_WHITE, BOOKING_WINAHOST, HOLIDU_WHITE, HOLIDU_WINAHOST,
    LOCASUN_WHITE, LOCASUN_WINAHOST, TRIPADVISOR_WHITE, TRIPADVISOR_WINAHOST, VRBO_WHITE, VRBO_WINAHOST
} from "../../Shared/Constants/logosConstants";

interface props {
    customBackgroundColor?: string,
    logosColor: string,
    opacity?: boolean
}

const SponsorsLogos = ({customBackgroundColor, logosColor, opacity = true}: props) => {

    const logos = [
        {
            'name': 'airbnb',
            'path': (logosColor === 'white') ? AIRBNB_WHITE : AIRBNB_WINAHOST,
            'alt': 'airbnb'
        },
        {
            'name': 'tripadvisor',
            'path': (logosColor === 'white') ? TRIPADVISOR_WHITE : TRIPADVISOR_WINAHOST,
            'alt': 'tripadvisor'
        },
        {
            'name': 'locasun',
            'path': (logosColor === 'white') ? LOCASUN_WHITE : LOCASUN_WINAHOST,
            'alt': 'locasun'
        },
        {
            'name': 'holidu',
            'path': (logosColor === 'white') ? HOLIDU_WHITE : HOLIDU_WINAHOST,
            'alt': 'holidu'
        },
        {
            'name': 'booking',
            'path': (logosColor === 'white') ? BOOKING_WHITE : BOOKING_WINAHOST,
            'alt': 'booking'
        },
        {
            'name': 'vrbo',
            'path': (logosColor === 'white') ? VRBO_WHITE : VRBO_WINAHOST,
            'alt': 'vrbo'
        },
    ];

    return (
        <Grid sx={{flexGrow: 1}} container spacing={2}
              style={{
                  backgroundColor: `${customBackgroundColor ?? 'unset'}`,
                  padding: '40px',
                  opacity: `${opacity ? 1 : 0}`
              }}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" mt={5}>
                    <Banner objects={logos} type={'image'}/>
                </Grid>
            </Grid>
        </Grid>
    );
}


export default SponsorsLogos;
