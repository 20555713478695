import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    Link
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { changeLeadHookData } from "../../hooks/leadsHook";
import { LeadInterface } from "../../interfaces/leadInterface";
import LegalTextContent from "../../Shared/sharedTexts/legalTextContent";
import { FormErrors } from "./Interfaces/errorsLeadInterface";

interface props {
    lead: LeadInterface;
    setLead: Function;
    formSubmit: Function;
    errors: FormErrors;
    setUsePolitical: Function;
    usePolitical: boolean;
}

const corporateGreen = "#008080";

const FormCreateLeadContractAt = ({
    lead,
    setLead,
    formSubmit,
    errors,
    setUsePolitical,
    usePolitical,
}: props) => {

    const handleChange = (key: string, value: string) => {
        changeLeadHookData(lead, setLead, key, value);
    };

    const checkedUsePoliticalAcceptance = () => {
        setUsePolitical(true);
        updateState({ dialog: false });
    };

    const [dialogState, setDialogState] = useState({
        political: false,
        dialog: false,
    });

    const updateState = (newState: Partial<{ political: boolean; dialog: boolean }>) =>
        setDialogState((prev) => ({ ...prev, ...newState }));

    const openDialogPoliticalAcceptance = () => {
        updateState({ dialog: true });
    };

    return (
        <Grid item xs={12} md={6}>
            <Box sx={{ width: "100%" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "16px",
                        marginBottom: "16px",
                    }}
                >
                    <TextField
                        label="Nombre"
                        name="nombre"
                        value={lead.user.name}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange("name", e.target.value)
                        }
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        variant="outlined"
                        fullWidth
                        required
                    />
                    <TextField
                        label="Apellidos"
                        name="apellidos"
                        value={lead.user.surname}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange("surname", e.target.value)
                        }
                        error={Boolean(errors.surname)}
                        helperText={errors.surname}
                        variant="outlined"
                        fullWidth
                        required
                    />
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: "16px",
                        marginBottom: "16px",
                    }}
                >
                    <TextField
                        label="Teléfono"
                        name="telefono"
                        value={lead.user.phone}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange("phone", e.target.value)
                        }
                        error={Boolean(errors.phone)}
                        helperText={errors.phone}
                        variant="outlined"
                        fullWidth
                        required
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={lead.user.email}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange("email", e.target.value)
                        }
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        variant="outlined"
                        fullWidth
                        required
                    />
                </Box>

                <FormControlLabel
                    control={
                        <Checkbox
                            name="political_acceptance"
                            checked={usePolitical}
                            onChange={(e) => setUsePolitical(e.target.checked)} // Cambié para que el checkbox se pueda marcar sin aceptar
                        />
                    }
                    label={
                        <Typography variant="body2">
                            He leído y acepto la{" "}
                            <Link
                                component="button"
                                onClick={openDialogPoliticalAcceptance}
                                color="primary"
                            >
                                política de privacidad
                            </Link>
                        </Typography>
                    }
                    sx={{
                        color: "#000",
                        marginBottom: "16px",
                    }}
                />
                {errors.political_acceptance && (
                    <Typography
                        variant="body2"
                        sx={{ color: "red", marginBottom: "16px" }}
                    >
                        {errors.political_acceptance}
                    </Typography>
                )}

                <Button
                    type="submit"
                    variant="contained"
                    sx={{
                        width: "100%",
                        padding: "12px",
                        backgroundColor: corporateGreen,
                        fontWeight: "bold",
                        borderRadius: "30px",
                        "&:hover": {
                            backgroundColor: "#2c6f6a",
                        },
                    }}
                    onClick={() => formSubmit()}
                >
                    Enviar formulario
                </Button>
            </Box>

            <Dialog
                open={dialogState.dialog}
                onClose={() => updateState({ dialog: false })}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    <LegalTextContent />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setUsePolitical(false);
                            updateState({ dialog: false });
                        }}
                        color="primary"
                    >
                        Cerrar
                    </Button>
                    <Button
                        onClick={checkedUsePoliticalAcceptance}
                        color="primary"
                        variant="contained"
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default FormCreateLeadContractAt;