import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import React from "react";
import {useTranslation} from "react-i18next";

const WeWorkWithTheBest = () => {

    const {t} = useTranslation('', {keyPrefix: 'mainPage.weWorkWithTheBest'});

    return (
        <Grid container justifyContent={'center'} mb={5}>
            <Grid item xs={12} sm={10} md={9} lg={9} xl={9}>
                <Grid container justifyContent={'center'} mt={6}>
                    <Grid item xs={11} textAlign={'center'}>
                        <Typography variant="body1" fontSize={'1.3rem'} p={2} fontWeight={400}
                                    color={'black'}
                                    dangerouslySetInnerHTML={createMarkup(t('weWorkWithTheBestTitle'))}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default WeWorkWithTheBest;
