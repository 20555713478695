import {Accordion, AccordionDetails, AccordionSummary, Box, Grid, Hidden, Tab, Tabs, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


import Translation from '../../../lang/es/translations.json';
import {SyntheticEvent, useState} from "react";
import {createMarkup, replaceWhiteSpace} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const Faq = () => {

    const {t} = useTranslation();

    let jsonFaqs = Translation;

    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid container justifyContent={'center'} mt={5} id={'faqs'}>
                <Grid item>
                    <Grid container justifyContent={'center'} mt={5}>
                        <Grid item textAlign={'center'}>
                            <Typography variant="h2" fontSize={'2.5em'} mb={5} fontWeight={600}
                                        color={'var(--main-winahost-color)'}
                                        dangerouslySetInnerHTML={createMarkup('Preguntas Frecuentes')}/>
                            <Typography variant="h2" fontSize={'1.3em'} mb={8} fontWeight={200} color={'black'}
                                        dangerouslySetInnerHTML={createMarkup('Aquí tienes algunos consejos e información por parte de Winahost')}/>
                        </Grid>
                    </Grid>
                    <Hidden mdDown>
                        {desktopFaqs(value, handleChange, jsonFaqs)}
                    </Hidden>
                    <Hidden mdUp>
                        {mobileFaqs(jsonFaqs)}
                    </Hidden>
                </Grid>
            </Grid>
        </>
    );
}

const desktopFaqs = (value: number, handleChange: any, jsonFaqs: any) => {
    return (

        <Box
            sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex'}}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={(e: SyntheticEvent) => {
                    const id = parseInt(e.target['id'].replace('vertical-tab-', ''));
                    handleChange(e, id);
                }}
                aria-label="Vertical tabs example"
                sx={{borderRight: 1, borderColor: 'divider'}}
            >
                {jsonFaqs['faqPage'].map((faq: any, index: number) => {
                    return <Tab key={index} label={faq.title} {...a11yProps(index)} />
                })}
            </Tabs>

            {jsonFaqs['faqPage'].map((faq: any, index: number) => {
                return (
                    <TabPanel key={index} value={value} index={index}>
                        {
                            faq.content.map((summary: any, secondIndex: number) => {
                                const id = replaceWhiteSpace(faq.title + ' ' + index, '_');
                                return (
                                    <Grid key={secondIndex} container justifyContent="center">
                                        <Grid item xs={12} mb={2}>
                                            <FaqSummary summary={summary} identification={id}/>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                    </TabPanel>
                )
            })}
        </Box>
    )
}

const mobileFaqs = (jsonFaqs: any) => {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={11} mb={11}>
                {
                    jsonFaqs['faqPage'].map((faq: any, index: number) => {
                        return <FaqSection key={index} faq={faq}/>
                    })
                }
            </Grid>
        </Grid>
    )
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{width: 'auto'}}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: '0 24px 24px 24px', width: 'calc(100vw - 450px)'}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}


const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const FaqSection = (faq: any) => {
    return (
        <Grid container justifyContent={'center'} mt={5}>
            <Grid item>
                <Typography variant={'h3'} fontSize={'1.5em'} mb={5} color={'black'}
                            fontWeight={700}
                            dangerouslySetInnerHTML={createMarkup(faq.faq.title)}/>
                {faq.faq.content.map((summary: any, index: number) => {
                    const id = replaceWhiteSpace(faq.faq.title + ' ' + index, '_');
                    return (
                        <Grid key={index} item mb={1}>
                            <FaqSummary summary={summary} identification={id}/>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
}

const FaqSummary = ({summary, identification}: { summary: any, identification: string }) => {

    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <Accordion key={identification} expanded={expanded === `panel${identification}`}
                   onChange={handleChange(`panel${identification}`)}>
            <AccordionSummary
                expandIcon={expanded === `panel${identification}` ? <RemoveIcon/> : <AddIcon/>}
                aria-controls={`panel-${identification}-content`}
                id={`panel-${identification}-header`}
            >
                <Typography color={'black'}
                            fontWeight={500}
                            textAlign={"left"}
                            dangerouslySetInnerHTML={createMarkup(summary.title)}/>
            </AccordionSummary>
            <AccordionDetails>
                <Typography color={'black'} textAlign={"left"}
                            dangerouslySetInnerHTML={createMarkup(summary.description)}/>
            </AccordionDetails>
        </Accordion>
    );
}


export default Faq;
