import {LeadInterface} from "../interfaces/leadInterface";


export const formatLeadMergeData = (lead: LeadInterface): Object => {
    return Object.assign({}, lead.property, lead.user, lead.origin);
}

export const formatRentalizeWithLeadMergeData = (lead: LeadInterface, rentalizer: Object): Object => {
    return Object.assign({}, formatLeadMergeData(lead), rentalizer);
}

export const changeLeadHookData = (lead: LeadInterface, setLead: Function, key: string, value: any) => {
    setLead({
        ...lead,
        user: {
            ...lead.user,
            [key]: value
        }
    });
}

export const changePropertyHookData = (lead: LeadInterface, setLead: Function, key: string, value: any) => {
    setLead({
        ...lead,
        property: {
            ...lead.property,
            [key]: value
        }
    });
}
