import '../../Styles/usPage.css';

import BaseLayout from "../../Shared/layouts/baseLayout";
import MeetWinahost from "./sections/meetWinahost";
import OurVisionAndMission from "./sections/ourVisionAndMission";
import OurValues from "./sections/ourValues";
import JoinToUs from "./sections/joinToUs";

const Us = () => {

    return (
        <BaseLayout>
            <MeetWinahost/>
            <OurVisionAndMission/>
            <OurValues/>
            <JoinToUs/>
        </BaseLayout>
    );
}

export default Us;
