import {Grid, Hidden} from "@mui/material";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import {formatLeadMergeData} from "../../../../hooks/leadsHook";
import {upsertHubspotContact} from "../../../../Shared/hooks/upsertHubspotContact";
import {useState} from "react";
import {gtagLandingContactNowConversion, metaLeadTrack} from "../../../../hooks/socialMeditionTags";
import CongratulationsContactNowForm from "../forms/congratulationsContactNowForm";
import BeginSectionForm from "./beginSectionForm";
import {SECOND_GIRL_DRAW} from "../../../../Shared/Constants/imageConstants";

interface props {
    lead: LeadInterface,
    setLead: Function
}

const BeginSectionContactNow = ({lead, setLead}: props) => {

    const [page, setPage] = useState(1);


    const sendContact = () => {
        addPage();
        handleSendLeadToHubspot();
    }

    const addPage = () => {
        setPage(page + 1);
    }

    const handleSendLeadToHubspot = () => {

        metaLeadTrack();
        gtagLandingContactNowConversion();

        const leadMergeData = formatLeadMergeData(lead);
        upsertHubspotContact(leadMergeData);
    }

    const showStepperForm = () => {
        switch (page) {
            case 1:
                return <BeginSectionForm lead={lead} setLead={setLead} currentFunction={sendContact}/>;
            case 2:
                return <CongratulationsContactNowForm/>;
            default:
                return null;
        }
    }

    return (
        <Grid container justifyContent={'center'} mt={9}>
            <Grid item style={styles.gridContainer} xs={12}>
                <Grid container direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                      mt={5}
                      mb={5}
                >
                    <Grid item xs={10} sm={8} md={4}>
                        {showStepperForm()}
                    </Grid>
                    <Hidden mdDown>
                        <Grid item md={2}>
                            <img src={SECOND_GIRL_DRAW}
                                 alt="secondgirl"
                                 width={'90%'}
                            />
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BeginSectionContactNow;

const styles = {
    gridContainer: {
        backgroundColor: 'var(--main-winahost-gray-color)',
    }
};
