import { Box, Grid, Paper, Typography } from "@mui/material";
import { createMarkup } from "../../../Shared/utils/textUtilsResources";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import BoxAutoAdjust from "../../../Shared/utils/boxAutoAdjust";
import { CHECK_CALENDAR, FILTER, GOAL, TAGS, TALK_IT, WARNING_TALK } from "../../../Shared/Constants/iconsConstants";

interface squaresInterface {
    image: string;
    alt: string;
    title: string;
    description: string;
}

const WinahostOfferServiceSection = () => {
    const { t } = useTranslation('', { keyPrefix: 'servicePage.winahostOfferServiceSection' });

    const squares: squaresInterface[] = [
        {
            image: FILTER,
            alt: 'filter',
            title: t('squares.filter.title'),
            description: t('squares.filter.description'),
        },
        {
            image: TAGS,
            alt: 'tags',
            title: t('squares.tags.title'),
            description: t('squares.tags.description'),
        },
        {
            image: TALK_IT,
            alt: 'talk_it',
            title: t('squares.talk_it.title'),
            description: t('squares.talk_it.description'),
        },
        {
            image: CHECK_CALENDAR,
            alt: 'check_calendar',
            title: t('squares.check_calendar.title'),
            description: t('squares.check_calendar.description'),
        },
        {
            image: WARNING_TALK,
            alt: 'warning_talk',
            title: t('squares.warning_talk.title'),
            description: t('squares.warning_talk.description'),
        },
        {
            image: GOAL,
            alt: 'goal',
            title: t('squares.goal.title'),
            description: t('squares.goal.description'),
        },
    ];

    const [boxHeight, setBoxHeight] = useState(0);

    const reAdjustBoxHeight = () => {
        const boxDataHeight = BoxAutoAdjust('itemOfferContent');
        setBoxHeight(boxDataHeight);
    };

    useEffect(() => {
        reAdjustBoxHeight();
    }, []);

    return (
        <>
            <Grid container justifyContent={'center'} mt={8}>
                <Grid item xs={11} textAlign={'center'}>
                    <Typography
                        variant="h2"
                        fontSize={'1.7em'}
                        mb={5}
                        fontWeight={600}
                        dangerouslySetInnerHTML={createMarkup(t('winahostOfferServiceSectionTitle'))}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent={'center'}
                mt={2}
                pt={5}
                pb={5}
                className={'mainAdvantagesContent'}
                style={{
                    maxWidth: '1250px', 
                    margin: '0 auto',
                    borderRadius: '20px', 
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                    backgroundColor: '#fff', 
                }}
            >
                <Grid item xs={12} sm={11} md={11.3} lg={9} xl={9}>
                    <Grid container justifyContent={'center'} spacing={3}>
                        {squares.map((square: squaresInterface, index: number) => {
                            return (
                                <Grid
                                    key={index}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    textAlign={'center'}
                                    className={'itemOfferContent'}
                                >
                                    <Paper
                                        style={{
                                            borderRadius: 20,
                                            height: boxHeight ? boxHeight : 'auto',
                                            padding: '20px',
                                        }}
                                    >
                                        <Grid container justifyContent={'center'}>
                                            <Grid item xs={10} textAlign={'center'} justifyContent={'center'}>
                                                <Box
                                                    m={1}
                                                    style={{
                                                        backgroundColor: 'var(--main-winahost-color)',
                                                        margin: '30px auto',
                                                        width: '70px',
                                                        height: '70px',
                                                        borderRadius: 20,
                                                    }}
                                                >
                                                    <img
                                                        src={square.image}
                                                        alt={square.alt}
                                                        style={{
                                                            position: 'relative',
                                                            top: '17px',
                                                            width: '40px',
                                                            height: '40px',
                                                        }}
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body1"
                                                    fontSize={'1.3em'}
                                                    mb={3}
                                                    fontWeight={400}
                                                    dangerouslySetInnerHTML={createMarkup(square.title)}
                                                />
                                                <Typography
                                                    variant="body1"
                                                    mb={5}
                                                    dangerouslySetInnerHTML={createMarkup(square.description)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default WinahostOfferServiceSection;