import {Box, Grid, Paper, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import BoxAutoAdjust from "../../../Shared/utils/boxAutoAdjust";
import {CONNECTIVITY, STATISTICS_UP, TECHNOLOGY} from "../../../Shared/Constants/iconsConstants";

interface squaresInterface {
    image: string,
    alt: string,
    title: string,
    description: string
}

const WinahostEasier = () => {

    const {t} = useTranslation('', {keyPrefix: 'servicePage.winahostEasier'});

    const squares: squaresInterface[] = [
        {
            image: CONNECTIVITY,
            alt: 'connection',
            title: t('winahostEasierSquares.connection.title'),
            description: t('winahostEasierSquares.connection.description')
        },
        {
            image: TECHNOLOGY,
            alt: 'technology',
            title: t('winahostEasierSquares.technology.title'),
            description: t('winahostEasierSquares.technology.description')
        },
        {
            image: STATISTICS_UP,
            alt: 'statisticsUp',
            title: t('winahostEasierSquares.statisticsUp.title'),
            description: t('winahostEasierSquares.statisticsUp.description')
        },
    ];

    const [boxHeight, setBoxHeight] = useState(0);

    const reAdjustBoxHeight = () => {
        const boxDataHeight = BoxAutoAdjust('itemEasierContent');
        setBoxHeight(boxDataHeight);
    }

    useEffect(() => {
        reAdjustBoxHeight();
    }, []);

    return (
        <Grid container justifyContent={'center'} mt={2} mb={5} pt={6} pb={6}
              style={{backgroundColor: 'var(--main-winahost-gray-color)'}}>
            <Grid item xs={12} sm={11} md={11} lg={8} xl={7}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={10} textAlign={'center'}>
                        <Typography variant="h2" fontSize={'1.7em'} mb={5} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup(t('winahostEasierTitle'))}/>
                        <Typography variant="body1" mb={5}
                                    dangerouslySetInnerHTML={createMarkup(t('winahostEasierSubTitle'))}/>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} spacing={3}>
                    {squares.map((square: squaresInterface, index: number) => {
                        return (
                            <Grid key={index} item xs={11} sm={6} md={4} textAlign={'center'} className={'itemEasierContent'}>
                                <Paper style={{borderRadius: 20, height: (boxHeight) ? boxHeight : 'auto'}}>
                                    <Grid container justifyContent={'center'}>
                                        <Grid item xs={12} textAlign={'center'} justifyContent={'center'}>
                                            <Box m={1} style={{
                                                backgroundColor: 'var(--main-winahost-color)',
                                                margin: '30px auto',
                                                width: '70px',
                                                height: '70px',
                                                borderRadius: 50
                                            }}>
                                                <img src={square.image}
                                                     alt={square.alt}
                                                     style={{
                                                         position: 'relative',
                                                         top: '17px',
                                                         width: '40px',
                                                         height: '40px'
                                                     }}
                                                />
                                            </Box>
                                            <Grid item xs={12} style={{padding: '0 40px 10px 40px'}}>
                                                <Typography variant="body1" fontSize={'1.3em'} mb={2} fontWeight={400}
                                                            dangerouslySetInnerHTML={createMarkup(square.title)}/>
                                                <Typography variant="body1" mb={3}
                                                            dangerouslySetInnerHTML={createMarkup(square.description)}/>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default WinahostEasier;
