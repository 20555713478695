import React, {ChangeEvent, useState} from "react";
import {Alert, Button, CircularProgress, Grid, Typography} from "@mui/material";
import AuthLayout from "./authLayout";
import CustomTextField from "../../components/customComponents/customTextField";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import {recoveryPasswordOfOwner} from "./hooks/recoveryPasswordOwnerAuthentificator";

const RecoveryPasswordPage = () => {

    const [email, setEmail] = useState('');

    const [displayError, setDisplayError] = useState<boolean>(false);
    const [displayLoader, setDisplayLoader] = useState<boolean>(false);
    const [displayEmailSent, setDisplayEmailSent] = useState<boolean>(false);


    const fillOwnerProperties = (value: string) => {
        setEmail(value);
    }

    const recoveryPasswordHook = () => {
        setDisplayLoader(true);
        setDisplayError(false);

        recoveryPasswordOfOwner(email).then(response => {
            if (response.code === 200) {
                setDisplayLoader(false);
                setDisplayEmailSent(true);
            }
            if (response.code !== 200) {
                setDisplayLoader(false);
                setDisplayError(true);
            }
        });
    }

    if (displayEmailSent) {
        return (
            <AuthLayout>
                <Grid container justifyContent={'center'} mt={1} mb={3} spacing={2}>
                    <Grid item xs={11}>

                        <Typography variant="body1" mt={1} mb={2} fontWeight={400} textAlign={'center'} fontSize={'1.2em'}
                                    color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>Mensaje enviado correctamente</strong>')}/>
                        <Typography variant="body1" mt={1} mb={3} fontWeight={400} textAlign={'center'} fontSize={'1.2em'}
                                    color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>Revisa la bandeja de entrada de tu correo electrónico.</strong>')}/>
                    </Grid>
                </Grid>
            </AuthLayout>
        );
    }

    return (
        <AuthLayout>
            <Grid container justifyContent={'center'} mt={1} mb={3} spacing={2}>
                <Grid item xs={11}>

                    <Typography variant="body1" mt={1} mb={5} fontWeight={300} textAlign={'center'} fontSize={'1.3em'}
                                color={'var(--main-winahost-color)'}
                                dangerouslySetInnerHTML={createMarkup('<strong>Recupera la contraseña</strong>')}/>

                    <CustomTextField
                        fullWidth
                        size={'small'}
                        required={true}
                        label={'Email'}
                        type={'email'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => fillOwnerProperties(e.target.value)}
                    />

                </Grid>

                {displayError && (
                    <Grid item xs={11} mt={1} textAlign={'center'}>
                        <Alert severity="error">Parece que el correo electrónico no es correcto, por favor, revísalo</Alert>
                    </Grid>
                )}

                {displayLoader && (
                    <Grid item xs={11} mt={1} textAlign={'center'}>
                        <CircularProgress/>
                    </Grid>
                )}

                <Grid item xs={11} mt={1} textAlign={'center'}>
                    <Button variant="contained"
                            style={{
                                width: '100%',
                                backgroundColor: 'var(--main-winahost-color)',
                                borderRadius: 25,
                                color: 'white',
                                fontWeight: 'bold'
                            }}
                            onClick={() => {
                                recoveryPasswordHook();
                            }}
                    >
                        Enviar
                    </Button>
                </Grid>
            </Grid>
        </AuthLayout>
    );
}

export default RecoveryPasswordPage;
