const redirectToId = (id: string) => {
    const scrollDiv = document.getElementById(id);
    if (scrollDiv) {
        const offsetTop = scrollDiv.offsetTop;
        window.scrollTo({ top: offsetTop - 60, behavior: 'smooth' });
    }
}

export const checkReferencePathId = (text: string) => {
    const checkText = text.match(/#(.*?)(\/|$)/);
    if (checkText) {
        redirectToId(checkText[1]);
    }
    return false;
}
