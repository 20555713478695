
export interface ReviewInterface {
    title: string,
    name: string,
    description: string,
    rating: number
}

const Reviews = () => {

    const reviewCollection: ReviewInterface[] = [
        {
            title: 'Muy satisfecho con Winahost! ',
            name: 'Jorge',
            description: 'Buenas tardes, como cliente tengo mi propiedad para gestión exclusiva en modelo Premium de Winahost y desde aquí quiero agradecer públicamente la excelente gestión que han tenido con todas las reservas de mi propiedad (en total 29) durante este año 2023. Muy contento con mi experiencia como cliente de Winahost. Excelente equipo !!',
            rating: 5
        },
        {
            title: 'Equipo muy eficaz',
            name: 'Jose Antonio Trilla',
            description: 'Muy contento con la eficacia del equipo de Winahost pendientes en todo momento de nuestra necesidad, personal muy cualificado y muy humano ante tus necesidades, sin duda lo recomiendo 100%. Eficaces y cercanos sería la palabra para definir al equipo de Winahost.',
            rating: 5
        },
        {
            title: 'Sorprendida!',
            name: 'Leire',
            description: '¡Encantada y sorprendida!Esta temporada ha ido todo genial: reservas, limpiezas, check-ins...Unos inquilinos se quedaron encerrados a las 10 de la noche y el equipo de winahost ya tenía al chico de mantenimiento allí en 20 minutos! Seguiremos con ellos seguro',
            rating: 5
        },
        {
            title: '100% recomendable!',
            name: 'Eduard Domènech',
            description: 'Hace tiempo se pusieron en contacto con nosotros y desde el primer momento nos encajó su propuesta. Ellos se encargan de todo (check in, check out, limpeza, contacto con los huéspedes, etc). En definitiva, no podemos estar mas satisfechos, gracias a Winahost las reservas de la casa se han duplicado. Lo recomiendo al 100%',
            rating: 5
        }
    ]

    return reviewCollection;

}


export default Reviews;
