import {Box, Grid, Skeleton} from "@mui/material";

const ResourceBlogSkeleton = () => {
    return (
        <Grid container justifyContent={'center'} mt={9}>
            <Grid item xs={12} sm={11} md={10} lg={8} xl={8}>
                <Grid container justifyContent={'center'} spacing="4">
                    {Array.from(new Array(6)).map((item, index) => {
                        return (
                            <Grid key={index} item xs={12} md={6}>
                                <Box sx={{ p: 2 }}>
                                    <Skeleton variant="rounded" width={'100%'} height={118} />
                                    <Skeleton />
                                    <Skeleton width="60%" />
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ResourceBlogSkeleton;
