import {
    Box,
    Button,
    Grid,
    Hidden,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import BaseLayout from "../../Shared/layouts/baseLayout";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import * as React from "react";
import {useEffect, useState} from "react";
import PropertyForm from "./formContact/propertyForm";
import UserForm from "./formContact/userForm";
import ReviseForm from "./formContact/reviseForm";
import {upsertHubspotContact} from "../../Shared/hooks/upsertHubspotContact";
import '../../Styles/mainPage.css';
import '../../Styles/contactCss.css';
import {LeadInterface} from "../../interfaces/leadInterface";
import {leadBase} from "../../Shared/baseData/leadBaseData";
import {formatLeadMergeData} from "../../hooks/leadsHook";
import {gtagLandingContactPageConversion, metaLeadTrack} from "../../hooks/socialMeditionTags";
import {GIRL_DRAW} from "../../Shared/Constants/imageConstants";


export interface validationContactFormPage {
    property: boolean,
    user: boolean,
    revise: boolean
}

const Contact = () => {
    const steps = ['Tu vivienda', 'Tus datos', 'Detalles finales'];


    const [lead, setLead] = useState<LeadInterface>(leadBase);

    const [validationFormPage, setValidationFormPage] = useState<validationContactFormPage>(
        {
            property: false,
            user: false,
            revise: false
        }
    );

    const [activeStep, setActiveStep] = useState(0);

    const [validateForm, setValidateForm] = useState(false);

    const handleNext = () => {
        if (activeStep === steps.length - 1 && validateForm) {

            lead.origin.origin = "contact";

            metaLeadTrack();
            gtagLandingContactPageConversion();

            const leadMergeData = formatLeadMergeData(lead);
            upsertHubspotContact(leadMergeData);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        goToTop();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        goToTop();
    };

    const goToTop = () => {
        window.scrollTo(0, 0);
    }

    const handleReset = () => {
        setActiveStep(0);
        setLead(leadBase);
    };

    const getCurrentForm = () => {
        switch (activeStep) {
            case 0:
                return <PropertyForm lead={lead} setLead={setLead} validationFormPage={validationFormPage}
                                     setValidationFormPage={setValidationFormPage}/>
            case 1:
                return <UserForm lead={lead} setLead={setLead} validationFormPage={validationFormPage}
                                 setValidationFormPage={setValidationFormPage}/>
            case 2:
                return <ReviseForm contact={lead} validateForm={validateForm} setValidateForm={setValidateForm}
                                   validationFormPage={validationFormPage}
                                   setValidationFormPage={setValidationFormPage}/>
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const checkValidationNextButton = () => {

        if (activeStep === 0 && !validationFormPage.property) return true;
        if (activeStep === 1 && !validationFormPage.user) return true;
        if (activeStep === 2 && !validationFormPage.revise) return true;
        return false;
    }

    return (
        <BaseLayout>
            <Grid container justifyContent={'center'} mt={5} mb={5}>
                <Hidden lgDown>
                    <Grid item md={3} p={2}>
                        <Typography variant={'body1'} fontSize={'1.3em'} color={'black'}
                                    fontWeight={400} mb={3}
                                    dangerouslySetInnerHTML={createMarkup('Contacta con un agente Winahost')}/>
                        <Typography variant={'body1'} fontSize={'1.3em'} color={'black'}
                                    mb={3}
                                    dangerouslySetInnerHTML={createMarkup('¡Muchos propietarios ya trabajan con nosotros!')}/>
                        <img src={GIRL_DRAW} alt="girl_draw" width={'60%'}
                             style={{margin: '0 auto', textAlign: 'center'}}/>
                    </Grid>
                </Hidden>
                <Grid item xs={11} sm={11} md={10} lg={8} xl={8}>
                    <Paper style={{borderRadius: 30, backgroundColor: 'rgba(130,187,187,0.3)'}} elevation={3}>
                        <Box sx={{width: '100%', padding: 3}}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label) => {
                                    const stepProps: { completed?: boolean } = {};
                                    const labelProps: {
                                        optional?: React.ReactNode;
                                    } = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Typography sx={{mt: 5, mb: 1}}
                                                dangerouslySetInnerHTML={createMarkup('Un agente de Winahost se pondrá en contacto contigo en breve.<br><br>Muchas gracias!')}/>
                                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                        <Box sx={{flex: '1 1 auto'}}/>
                                        <Button onClick={() => handleReset()}>Resetear</Button>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Box sx={{mt: 1, mb: 1, p: 1}}>{getCurrentForm()}</Box>
                                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={() => handleBack()}
                                            sx={{mr: 1}}
                                        >
                                            Atrás
                                        </Button>
                                        <Box sx={{flex: '1 1 auto'}}/>
                                        <Button onClick={() => handleNext()} disabled={checkValidationNextButton()}>
                                            {activeStep === steps.length - 1 ? 'Finalizar' : 'Siguiente'}
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            )}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </BaseLayout>
    );
}

export default Contact;
