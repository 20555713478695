import {Grid, Rating, Typography} from "@mui/material";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import { ReviewInterface } from "./reviews";
import {useEffect, useState} from "react";
import BoxAutoAdjust from "../../../../Shared/utils/boxAutoAdjust";

interface props {
    review: ReviewInterface
}

const ReviewComponent = ({review}: props) => {

    const [boxHeight, setBoxHeight] = useState(0);

    const reAdjustBoxHeight = () => {
        const boxDataHeight = BoxAutoAdjust('itemReviewContent');
        setBoxHeight(boxDataHeight);
    }

    useEffect(() => {
        reAdjustBoxHeight();
    }, []);

    return (
        <Grid container justifyContent={'center'} textAlign={'center'}>
            <Grid item xs={12} mb={3}>
                <Typography variant="body1" fontWeight={600} dangerouslySetInnerHTML={createMarkup(review.title)}/>
            </Grid>
            <Grid item xs={12} mb={3} style={{height: 'auto'}} className={'itemReviewContent'}>
                <Typography variant="body1" fontSize={'.7em'} dangerouslySetInnerHTML={createMarkup(review.description)}/>
            </Grid>
            <Grid item xs={12} mb={3}>
                <Rating name="half-rating-read" defaultValue={review.rating} readOnly/>
            </Grid>
            <Grid item xs={12} mb={3}>
                <Typography variant="body1" fontWeight={600} dangerouslySetInnerHTML={createMarkup(review.name)}/>
            </Grid>
        </Grid>
    );
}

export default ReviewComponent;
