export const createMarkup = (c: string) => {
    return { __html: c };
}

export const replaceWhiteSpace = (str: string, replaceWord: string) => {
    return str.replace(/\s/g, replaceWord);
}

export const firstCharacters = (str: string, length: number|null = null) => {

    const count = length ?? 100;

    const splitWord = str.split(' ');

    let splitString = [];
    for (let i = 0; i < splitWord.length; i++) {
        if (i >= count) {
            break;
        }
        splitString.push(splitWord[i]);
    }

    return splitString.join(' ').trimEnd() + ' ...';
}
