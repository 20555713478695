import { Grid } from "@mui/material";
import * as React from "react";
import SocialNetworks from "./socialNetworks";
import { WINAHOST_HORIZONTAL_WHITE_LOGO } from "../../Shared/Constants/imageConstants";

export const ContactSocialNetworkDetails = () => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={10} textAlign={'center'}>
                        <img 
                            src={WINAHOST_HORIZONTAL_WHITE_LOGO}
                            alt="winahost-logo"
                            className="footerWinahostLogo"
                        />
                        <SocialNetworks />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}