import { Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ES_CONTACT } from "../../../routes/constantsRoutes";

const BeginSection = () => {
    const { t } = useTranslation('', { keyPrefix: 'servicePage.beginSection' });
    const contactPath = ES_CONTACT;

    const scrollToTop = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12}>
                <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={3}
                      mt={5}
                      mb={5}
                >
                    <Grid item>
                        <Link
                            rel={'canonical'}
                            key={'main_page'}
                            href={`${contactPath}`}
                            underline="none"
                            className={'custom-winahost-button'}
                            onClick={scrollToTop} // Llama a la función cuando se haga clic
                        >
                            <span style={{ fontWeight: 'bold' }}>
                                {t('beginSectionButton')}
                            </span>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BeginSection;
