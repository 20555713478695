import {Grid} from "@mui/material";
import CustomTextField from "../../../../components/customComponents/customTextField";
import {ChangeEvent} from "react";
import * as React from "react";
import {changeLeadHookData} from "../../../../hooks/leadsHook";
import {LeadInterface} from "../../../../interfaces/leadInterface";


interface props {
    lead: LeadInterface,
    setLead: Function
}

const LeadBasicFormData = ({lead, setLead}: props) => {

    const changeUserData = (key: string, value: string) => {
        changeLeadHookData(lead, setLead, key, value);
    }

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'} spacing={3}>

                    <Grid item xs={12}>
                        <CustomTextField fullWidth
                                         type={'email'}
                                         size={'small'}
                                         required={true}
                                         value={lead.user.email}
                                         label={'Correo electrónico'}
                                         id="userContactFormEmailDetail"
                                         onChange={(e: ChangeEvent<HTMLInputElement>) => changeUserData('email', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextField fullWidth
                                         size={'small'}
                                         required={true}
                                         value={lead.user.phone}
                                         label={'Teléfono'}
                                         id="userContactFormPhoneDetail"
                                         onChange={(e: ChangeEvent<HTMLInputElement>) => changeUserData('phone', e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LeadBasicFormData;
