import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {WORLD_BALL} from "../../../Shared/Constants/imageConstants";

const MeetWinahost = () => {

    const {t} = useTranslation();

    return (
        <Grid container justifyContent={'center'} mt={9} mb={5}>
            <Grid item xs={11} sm={11} md={10} lg={9} xl={8}>
                <Grid container justifyContent={'center'} spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={'h2'} fontSize={'1.7em'} mb={2} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup('Conoce Winahost')}/>

                        <Typography variant={'body1'} fontWeight={500} mb={5}
                                    dangerouslySetInnerHTML={createMarkup('Winahost surgió de la propia necesidad de sus creadores.')}/>

                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup('El modelo convencional de alquiler de alojamientos turísticos, presenta unos ingresos escasos comparado con los obtenidos gracias a combinar la aplicación de la tecnología Big Data y las posibilidades de las nuevas plataformas que soportan este mercado global.')}/>

                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup('Es por eso que los fundadores de Winahost, supimos identificar un “problema” y brindarle una solución que ayude a los propietarios a beneficiarse al máximo de su vivienda, todo esto, sin tener que preocuparse del tiempo que comporta la gestión completa del alquiler a corto plazo.')}/>

                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup('Nos sabemos poner en el lugar de nuestros clientes porque nosotros mismos tuvimos esa misma necesidad.')}/>


                    </Grid>
                    <Grid item xs={12} sm={9} md={6} lg={6} style={{margin: 'auto auto', textAlign: 'center'}}>
                            <img src={WORLD_BALL} alt={"bedroom_house"} width={'90%'} style={{borderRadius: 30, margin: 'auto auto', textAlign: 'center'}}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default MeetWinahost;
