import React, {ChangeEvent, useEffect, useState} from "react";
import {Button, CircularProgress, Grid, IconButton, InputAdornment, Tooltip, Typography} from "@mui/material";
import AuthLayout from "./authLayout";
import CustomTextField from "../../components/customComponents/customTextField";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import {
    generateRandomPassword,
    textHasAtLeastACapitalLetter,
    textHasAtLeastALowerCaseLetter, textHasAtLeastANumber, textHasAtLeastASpecialCharacter,
    textHasAtLeastEightCharacters, validationPassword
} from "../../Shared/utils/validations";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {checkOwnerTokenRecoveryPassword} from "./hooks/checkOwnerTokenRecoveryPassword";
import {useNavigate, useParams} from "react-router-dom";
import {LOGIN_OWNERS} from "../../routes/constantsRoutes";
import {changeOwnerPasswordWithToken} from "./hooks/changeOwnerPasswordWithToken";

const ChangePasswordPage = () => {
    const navigate = useNavigate();
    let {token} = useParams();

    const [passwords, setPasswords] = useState({
        'password': '',
        'repeatPassword': '',
    });

    const [validatePassword, setValidatePassword] = useState({
        passwordLength: false,
        capitalLetter: false,
        lowerCaseLetter: false,
        number: false,
        specialCharacter: false,
        equals: false
    })

    const [availabilityButton, setAvailabilityButton] = useState<boolean>(true);

    const [visibleIconPassword, setVisibleIconPassword] = useState<JSX.Element>(<VisibilityOffIcon/>);
    const [visibleIconRepeatPassword, setVisibleIconRepeatPassword] = useState<JSX.Element>(<VisibilityOffIcon/>);
    const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
    const [visibleRepeatPassword, setVisibleRepeatPassword] = useState<boolean>(false);
    const [showGeneratePassword, setShowGeneratePassword] = useState<boolean>(false);
    const [displayLoader, setDisplayLoader] = useState<boolean>(false);

    const [canChangePassword, setCanChangePassword] = useState<boolean>(true);
    const [displayTokenNotValid, setDisplayTokenNotValid] = useState<boolean>(false);
    const [displayPasswordChanged, setDisplayPasswordChanged] = useState<boolean>(false);

    const showOrHidePassword = () => {
        setVisiblePassword(!visiblePassword);
        if (visiblePassword) {
            setVisibleIconPassword(<VisibilityOffIcon/>);
        } else {
            setVisibleIconPassword(<VisibilityIcon/>);
        }
    }

    const showOrHideRepeatPassword = () => {
        setVisibleRepeatPassword(!visibleRepeatPassword);
        if (visibleRepeatPassword) {
            setVisibleIconRepeatPassword(<VisibilityOffIcon/>);
        } else {
            setVisibleIconRepeatPassword(<VisibilityIcon/>);
        }
    }

    const fillOwnerProperties = (field: string, value: string) => {
        setPasswords({...passwords, [field]: value});
        setShowGeneratePassword(false);
    }

    const changePasswords = () => {
        setDisplayLoader(true);

        if (token) {
            changeOwnerPasswordWithToken(token, passwords.password).then(response => {
                if (response.code === 200) {
                    setDisplayLoader(false);
                    setDisplayPasswordChanged(true);
                }
                if (response.code !== 200) {
                    setDisplayLoader(false);
                }
            });
        }
    }

    const validatePasswordFormat = () => {
        setValidatePassword({
            passwordLength: textHasAtLeastEightCharacters(passwords.password),
            capitalLetter: textHasAtLeastACapitalLetter(passwords.password),
            lowerCaseLetter: textHasAtLeastALowerCaseLetter(passwords.password),
            number: textHasAtLeastANumber(passwords.password),
            specialCharacter: textHasAtLeastASpecialCharacter(passwords.password),
            equals: passwords.password === passwords.repeatPassword && textHasAtLeastEightCharacters(passwords.password)
        })

        setAvailabilityButton(!(validationPassword(passwords.password) && passwords.password === passwords.repeatPassword));
    }

    const generateNewPassword = () => {
        const newPassword = generateRandomPassword();

        setPasswords({
            password: newPassword,
            repeatPassword: newPassword
        })

        setShowGeneratePassword(true);
    }

    const copyPassword = () => {
        navigator.clipboard.writeText(passwords.password);
    }

    useEffect(() => {

        if (token && canChangePassword) {
            checkOwnerTokenRecoveryPassword(token).then(response => {
                if (response.code === 200) {
                    setCanChangePassword(false);
                }
                if (response.code !== 200) {
                    setCanChangePassword(false);
                    setDisplayTokenNotValid(true);
                }
            })
        }

        validatePasswordFormat();
    }, [passwords]);

    if (canChangePassword) {
        return (
            <AuthLayout>
                <Grid container justifyContent={'center'} mt={1} mb={3} spacing={2}>
                    <Grid item xs={11}>

                        <Typography variant="body1" mt={1} mb={2} fontWeight={400} textAlign={'center'}
                                    fontSize={'1.2em'}
                                    color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>Validando token</strong>')}/>

                        <CircularProgress/>
                    </Grid>
                </Grid>
            </AuthLayout>
        );
    }

    if (displayTokenNotValid) {
        return (
            <AuthLayout>
                <Grid container justifyContent={'center'} mt={1} mb={3} spacing={2}>
                    <Grid item xs={11}>

                        <Typography variant="body1" mt={1} mb={2} textAlign={'center'}
                                    color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>El código de validación no es correcto, renueve la contraseña de nuevo o póngase en contacto con el administrador</strong>')}/>

                        <Typography variant="body1" mt={1} mb={3} fontWeight={400} textAlign={'center'}
                                    fontSize={'1.2em'}
                                    color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>info@winahost.com</strong>')}/>
                    </Grid>
                </Grid>
            </AuthLayout>
        );
    }

    if (displayPasswordChanged) {
        return (
            <AuthLayout>
                <Grid container justifyContent={'center'} mt={1} mb={3} spacing={2}>
                    <Grid item xs={11}>

                        <Typography variant="body1" mt={1} mb={3} fontWeight={400} textAlign={'center'}
                                    fontSize={'1.2em'}
                                    color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup('<strong>Contraseña cambiada correctamente</strong>')}/>

                        <Grid item xs={12} mt={2} textAlign={'center'}>
                            <Button variant="contained"
                                    className={'custom-winahost-button'}
                                    style={{
                                        width: '100%',
                                    }}
                                    onClick={() => {
                                        navigate(LOGIN_OWNERS);
                                    }}
                            >
                                Ir a la pantalla de acceso
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </AuthLayout>
        );
    }

    return (
        <AuthLayout>
            <Grid container justifyContent={'center'} mt={1} mb={3} spacing={2}>

                <Grid item xs={11} mt={1}>

                    <Typography variant="body1" mt={1} ml={1} textAlign={'left'}
                                fontSize={14}
                                color={'var(--main-winahost-color)'}
                                dangerouslySetInnerHTML={createMarkup('La contraseña debe contener almenos:')}/>

                    <Typography variant="body1"  ml={1} textAlign={'left'}
                                fontSize={12}
                                color={(validatePassword.passwordLength) ? 'var(--main-winahost-color)' : 'indianred'}
                                dangerouslySetInnerHTML={createMarkup('Mínimo 8 carácteres')}/>

                    <Typography variant="body1" ml={1} textAlign={'left'}
                                fontSize={12}
                                color={(validatePassword.capitalLetter) ? 'var(--main-winahost-color)' : 'indianred'}
                                dangerouslySetInnerHTML={createMarkup('Una mayúscula')}/>

                    <Typography variant="body1" ml={1} textAlign={'left'}
                                fontSize={12}
                                color={(validatePassword.lowerCaseLetter) ? 'var(--main-winahost-color)' : 'indianred'}
                                dangerouslySetInnerHTML={createMarkup('Una minúscula')}/>

                    <Typography variant="body1" ml={1} textAlign={'left'}
                                fontSize={12}
                                color={(validatePassword.number) ? 'var(--main-winahost-color)' : 'indianred'}
                                dangerouslySetInnerHTML={createMarkup('Un número')}/>

                    <Typography variant="body1" ml={1} textAlign={'left'}
                                fontSize={12}
                                color={(validatePassword.specialCharacter) ? 'var(--main-winahost-color)' : 'indianred'}
                                dangerouslySetInnerHTML={createMarkup('Un carácter especial <small>(!@#$%^*()-_=+|{}?.,)</small>')}/>

                    <Typography variant="body1" mb={3} ml={1} textAlign={'left'}
                                fontSize={12}
                                color={(validatePassword.equals) ? 'var(--main-winahost-color)' : 'indianred'}
                                dangerouslySetInnerHTML={createMarkup('Deben de coincidir los dos campos')}/>


                    <CustomTextField
                        fullWidth
                        size={'small'}
                        required={true}
                        label={'Password'}
                        value={passwords.password}
                        type={visiblePassword ? 'text' : 'password'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => fillOwnerProperties('password', e.target.value)}
                        helperText="*Para visualizar la contraseña haz click en el ojo"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{backgroundColor: "transparent"}} onClick={() => {
                                    showOrHidePassword();
                                }}>
                                    {visibleIconPassword}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={11} mt={1}>
                    <CustomTextField
                        fullWidth
                        size={'small'}
                        required={true}
                        label={'Repetir psassword'}
                        value={passwords.repeatPassword}
                        type={visibleRepeatPassword ? 'text' : 'password'}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => fillOwnerProperties('repeatPassword', e.target.value)}
                        helperText="*Para visualizar la contraseña haz click en el ojo"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" style={{backgroundColor: "transparent"}} onClick={() => {
                                    showOrHideRepeatPassword();
                                }}>
                                    {visibleIconRepeatPassword}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                {showGeneratePassword && (
                    <Grid item xs={11} textAlign={'center'}>
                        <Typography variant={'caption'} mt={3} ml={1} mr={2} fontSize={'1.2em'}
                                    color={'var(--main-winahost-color)'}
                                    dangerouslySetInnerHTML={createMarkup(passwords.password)}/>
                        <Tooltip title="Copiar">
                            <IconButton>
                                <ContentCopyIcon cursor={'pointer'} onClick={() => copyPassword()}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}


                <Grid item xs={11} textAlign={'center'}>
                    <Button variant="contained"
                            size={'small'}
                            className={'custom-winahost-button'}
                            style={{
                                width: '100%',
                                height: '60%'
                            }}
                            onClick={() => {
                                generateNewPassword();
                            }}
                    >
                        Generar automáticamente la contraseña
                    </Button>
                </Grid>

                {displayLoader && (
                    <Grid item xs={11} mt={1} textAlign={'center'}>
                        <CircularProgress/>
                    </Grid>
                )}

                <Grid item xs={11} mt={1} textAlign={'center'}>
                    <Button variant="contained"
                            disabled={availabilityButton}
                            className={'custom-winahost-button'}
                            style={{
                                width: '100%',
                            }}
                            onClick={() => {
                                changePasswords();
                            }}
                    >
                        Enviar
                    </Button>
                </Grid>
            </Grid>
        </AuthLayout>
    );
}

export default ChangePasswordPage;
