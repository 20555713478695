import {createContext, useContext, useEffect, useState} from "react";
import i18n from "i18next";

interface props {
    children: JSX.Element
}

// @ts-ignore
const LanguageContext = createContext();

export const useLanguageContext = () => {
    return useContext(LanguageContext);
}

const availabilityLanguages = ['', 'en'];

export const LanguageProvider = ({children}: props) => {

    const [lang, setLang] = useState<string>('es');

    useEffect(() => {
        const pathLanguage = window.location.pathname.split('/')[1];
        if (availabilityLanguages.includes(pathLanguage)) {
            i18n.changeLanguage(pathLanguage === '' ? 'es' : pathLanguage);
            setLang(pathLanguage === '' ? 'es' : pathLanguage);
        }
    }, [])

    return (
        <LanguageContext.Provider value={{lang}}>
            {children}
        </LanguageContext.Provider>
    );
}
