import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import * as React from "react";


const CongratulationsContactNowForm = () => {
    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} textAlign={'center'}>
                        <Typography variant={'body1'} fontWeight={600} mb={3} mt={3} fontSize={'1.5em'}
                                    dangerouslySetInnerHTML={createMarkup('Gracias por confiar en nosotros')}/>

                        <Typography variant={'body1'} fontWeight={400} mb={3} mt={3} fontSize={'1.3em'}
                                    dangerouslySetInnerHTML={createMarkup('Un agente de Winahost se pondrá en contacto contigo en breve')}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CongratulationsContactNowForm;
