import * as React from "react";
import ManagementHeader from "./sections/managementHeader";
import SponsorsLogos from "../../components/sponsors/sponsorsLogos";
import PointsSection from "./sections/pointsSection";
import BeginSection from "./sections/beginSection";
import BaseLayout from "../../Shared/layouts/baseLayout";
import WinahostEasier from "./sections/winahostEasier";
import WinahostOfferServiceSection from "./sections/winahostOfferServiceSection";
import Property360Space from "./sections/property360Space";
import ManagementInOurHands from "./sections/managementInOurHands";
import '../../Styles/servicePage.css'



const Service = () => {

    return (
        <BaseLayout>
            <ManagementHeader/>
            <SponsorsLogos logosColor={'winahost'}/>
            <WinahostEasier/>
            <WinahostOfferServiceSection/>
            <ManagementInOurHands/>
            <Property360Space/>
            <PointsSection/>
            <BeginSection/>
        </BaseLayout>
    );
}

export default Service;
