import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import ScreenSizeResource from "../../../Shared/utils/screenSizeResource";
import {useTranslation} from "react-i18next";
import {BOMBILLA} from "../../../Shared/Constants/imageConstants";

const OurVisionAndMission = () => {

    const {t} = useTranslation();

    const {width} = ScreenSizeResource();

    const reverse = (width < 900) ? 'column-reverse' : 'row';

    return (
        <Grid container justifyContent={'center'} mt={9} mb={5}>
            <Grid item xs={11} sm={11} md={10} lg={9} xl={8}>
                <Grid container justifyContent={'center'} spacing={3} direction={reverse}>
                    <Grid item xs={12} sm={11} md={6} lg={6} style={{margin: 'auto', textAlign: 'center'}}>
                        <img src={BOMBILLA} alt={"bedroom_house"} width={'90%'} style={{borderRadius: 30}}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography variant={'h2'} fontSize={'1.7em'} mb={2} fontWeight={600}
                                    dangerouslySetInnerHTML={createMarkup('Nuestra visión y misión')}/>
                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup('Desde Winahost queremos promover la nueva economía colaborativa conectando a ciudadanos, propietarios y profesionales de todo el mundo, ofreciendo la mejor experiencia para nuestros huéspedes y aumentando la rentabilidad y satisfacción de nuestros clientes.')}/>

                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup('Con nuestro modelo de gestión de propiedades de alquiler a corto plazo basado en la continua búsqueda de la excelencia, queremos ser la empresa digital referente a nivel mundial en la gestión integral de servicios de alquiler turístico y de temporada desde el Bigdata y la conexión on-line con los principales canales de reservas digitales.')}/>

                        <Typography variant={'body1'} mb={5}
                                    dangerouslySetInnerHTML={createMarkup('Tenemos la vocación de estar presentes de manera progresiva en las zonas turísticas más codiciadas por los viajeros de todo el mundo.')}/>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default OurVisionAndMission;
