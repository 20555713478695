import React, { useState } from "react";
import BaseWithoutNavBarLayout from "../../../Shared/layouts/baseWithoutNavBarLayout";
import Statistics from "../../pricesSection/sections/statistics";
import WinahostOfferServiceSectionLanding from "../../serviceSection/sections/winahostOfferServiceSectionLanding";
import SectionNew from "../../serviceSection/sections/sectionNew";
import BeginSectionLanding from "../../serviceSection/sections/beginSectionLanding";
import ReviewClientsTrustBox from "../../mainSection/sections/reviewClientsTrustBox";
import { LeadInterface } from "../../../interfaces/leadInterface";
import { leadFormContactPropertiesBase } from "../../../Shared/baseData/leadBaseData";
import HeaderMainSectionContactProperties from "./section/headerMainSectionContactProperties";
import './styles/customMainPage.css';

const ContactProperties = () => {
    const [lead, setLead] = useState<LeadInterface>(leadFormContactPropertiesBase);

    return (
        <BaseWithoutNavBarLayout>
            <HeaderMainSectionContactProperties lead={lead} setLead={setLead} />
            <Statistics />
            <WinahostOfferServiceSectionLanding />
            <SectionNew />
            <ReviewClientsTrustBox />
            <BeginSectionLanding />
        </BaseWithoutNavBarLayout> 
    );
}

export default ContactProperties;