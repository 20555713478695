import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import * as React from "react";

const ShowErrorMessageRentalizeForm = () => {
    return (
        <Grid container justifyContent={'center'} mb={4}>
            <Grid container justifyContent={'center'} pl={2} pr={4}>
                <Grid item xs={12}>
                    <Typography variant="body1" fontSize={'1.3em'} mb={3} fontWeight={600} color={'var(--main-winahost-color)'}
                                dangerouslySetInnerHTML={createMarkup('En estos momentos no podemos acceder a los datos fuente para tu petición, vuelve a intentarlo en unos minutos')} textAlign={'center'}/>
                    <Typography variant="body1" fontSize={'1.3em'} mb={1} fontWeight={500} color={'var(--main-winahost-color)'}
                                dangerouslySetInnerHTML={createMarkup('O si lo prefieres ponte en contacto con nosotros en <a href="mailto:info@winahost.com">info@winahost.com</a>')} textAlign={'center'}/>
                    <Typography variant="body1" fontSize={'1.3em'} mb={1} fontWeight={500} color={'var(--main-winahost-color)'}
                                dangerouslySetInnerHTML={createMarkup('o llámanos al <a href="tel:+34930500225">+34 930 500 225</a>')} textAlign={'center'}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ShowErrorMessageRentalizeForm;
