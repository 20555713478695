import React, { useState, useEffect } from 'react';

interface AnimatedTextProps {
  text: string;
  typingSpeed?: number;
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ text = '', typingSpeed = 100 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let currentIndex = 0;
    setDisplayedText('');
    setIsTyping(true);

    const typeText = () => {
      if (currentIndex < text.length) {
        setDisplayedText(text.slice(0, currentIndex + 1));
        currentIndex++;
      } else {
        setIsTyping(false);
      }
    };

    const intervalId = setInterval(typeText, typingSpeed);

    return () => {
      clearInterval(intervalId);
    };
  }, [text, typingSpeed]);

  return (
    <span className="animated-text-wrapper">
      {displayedText}
      {isTyping && <span className="animated-cursor">|</span>}
    </span>
  );
};

export default AnimatedText;