import './banner.css';
import { Typography } from "@mui/material";
import { createMarkup } from "../../Shared/utils/textUtilsResources";

interface BannerObject {
    width?: string;
    height?: string;
    path: string;
    alt: string;
}

interface BannerString {
    value: string
}

interface Props {
    objects: BannerObject[] | BannerString[],
    type: string
}

const Banner = ({ objects, type }: Props) => {
    const isBannerStringArray = (objects: BannerObject[] | BannerString[]): objects is BannerString[] => {
        return (objects as BannerString[])[0]?.value !== undefined;
    };

    const isBannerObjectArray = (objects: BannerObject[] | BannerString[]): objects is BannerObject[] => {
        return (objects as BannerObject[])[0]?.path !== undefined;
    };

    const getObjectType = (objects: BannerObject[] | BannerString[], type: string) => {
        switch (type) {
            case 'text':
                return isBannerStringArray(objects) ? textType(objects) : null;
            case 'image':
                return isBannerObjectArray(objects) ? pictureType(objects) : null;
            default:
                return null;
        }
    }

    return (
        <div className="inner">
            <div className="wrapper">
                {getObjectType(objects, type)}
                {getObjectType(objects, type)}
                {getObjectType(objects, type)}
            </div>
        </div>
    );
};

const textType = (objects: BannerString[]) => {
    return (
        <section className={'bannerTextSection'}>
            {objects.map((object, index) => (
                <Typography key={index} variant="body1" fontSize={'2.3rem'} mb={2} fontWeight={700}
                            color={'var(--main-winahost-color)'}
                            dangerouslySetInnerHTML={createMarkup(object.value)} />
            ))}
        </section>
    )
}

const pictureType = (objects: BannerObject[]) => {
    return (
        <section className={'bannerImageSection'}>
            {objects.map((object, index) => {
                const width = object.width ?? 'auto';
                const height = object.height ?? 'auto';

                return (
                    <img key={index} className={"bannerImageImage"} src={object.path.toString()} alt={object.alt} style={{
                        width: width,
                        height: height
                    }} />
                );
            })}
        </section>
    )
}

export { Banner };