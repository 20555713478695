import {Grid, Typography} from "@mui/material";
import {createMarkup} from "../../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {
    CALL_CENTER_WORKER,
    GIVE_KEYS,
    TAKING_A_PICTURE,
    WASHING_MACHINE_SECTION
} from "../../../Shared/Constants/imageConstants";

interface FlipCardInterface {
    front: {
        picture: string,
        title: string
    },
    back: {
        title: string,
        description: string
    }
}

const ManagementInOurHands = () => {

    const {t} = useTranslation('', {keyPrefix: 'servicePage.managementInOurHands'});

    const [backHeight, setBackHeight] = useState(0);

    const flipCards: FlipCardInterface[] = [
        {
            front: {
                picture: GIVE_KEYS,
                title: t('managementInOurHandsSections.checkin-out.front.title')
            },
            back: {
                title: t('managementInOurHandsSections.checkin-out.back.title'),
                description: t('managementInOurHandsSections.checkin-out.back.description')
            }
        },
        {
            front: {
                picture: WASHING_MACHINE_SECTION,
                title: t('managementInOurHandsSections.cleaning.front.title')
            },
            back: {
                title: t('managementInOurHandsSections.cleaning.back.title'),
                description: t('managementInOurHandsSections.cleaning.back.description')
            }
        },
        {
            front: {
                picture: CALL_CENTER_WORKER,
                title: t('managementInOurHandsSections.communication.front.title')
            },
            back: {
                title: t('managementInOurHandsSections.communication.back.title'),
                description: t('managementInOurHandsSections.communication.back.description')
            }
        },
        {
            front: {
                picture: TAKING_A_PICTURE,
                title: t('managementInOurHandsSections.platform.front.title')
            },
            back: {
                title: t('managementInOurHandsSections.platform.back.title'),
                description: t('managementInOurHandsSections.platform.back.description')
            }
        }
    ];

    useEffect(() => {
        setTimeout(() => {
            const frontImage = document.getElementsByClassName('flip-card-front')[0];
            const imageSize = frontImage.getElementsByTagName('img')[0].clientHeight;
            setBackHeight(imageSize);
        }, 1000)
    }, [])

    return (
        <Grid container justifyContent={'center'} mt={9} mb={5}>
            <Grid item xs={11}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} sm={10} md={10} lg={9} xl={8}>
                        <Typography variant="body1" fontSize={'1.7em'} mb={5} fontWeight={600} textAlign={'center'}
                                    dangerouslySetInnerHTML={createMarkup(t('managementInOurHandsTitle'))}/>
                        <Typography variant="body1" textAlign={'center'} pl={2} pr={2}
                                    dangerouslySetInnerHTML={createMarkup(t('managementInOurHandsSubTitle'))}/>
                        <Grid container justifyContent={'center'} spacing={3}>
                            {
                                flipCards.map((flip: FlipCardInterface, index: number) => {
                                    return (
                                        <Grid key={index} item xs={11} sm={6} md={6} lg={3} textAlign={'center'}>
                                            <div className="flip-card">
                                                <div className="flip-card-inner">
                                                    <div className="flip-card-front">
                                                        <img src={flip.front.picture} alt="Avatar"
                                                             style={{width: '100%'}}/>
                                                        <span
                                                            className={'flip-card-front-span'}>{flip.front.title}</span>
                                                    </div>
                                                    <div className="flip-card-back" style={{height: backHeight ? backHeight + 'px' : 'auto'}}>
                                                        <Typography variant="body1" mb={2}
                                                                    fontWeight={400} className={'flip-car-title'}
                                                                    dangerouslySetInnerHTML={createMarkup(flip.back.title)}/>
                                                        <Typography variant="body1" className={'flip-car-body'}
                                                                    dangerouslySetInnerHTML={createMarkup(flip.back.description)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ManagementInOurHands;
