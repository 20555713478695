import BaseLayout from "../../../Shared/layouts/baseLayout";
import CalculatorPriceBase from "./calculatorPriceBase";
import Statistics from "./statistics";
import * as React from "react";


interface props {
    children: JSX.Element
}

const PlanPricePageSection = ({children}: props) => {
    return (
        <BaseLayout>
            {children}
            <CalculatorPriceBase/>
            <Statistics />
        </BaseLayout>
    );
}

export default PlanPricePageSection;
